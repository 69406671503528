import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

import { POLICY_BASE_API } from '@pumpkincare/config';
import { buildAuthHeaders } from '@pumpkincare/shared';

export function searchVetClinic(term) {
  const url = `${POLICY_BASE_API}/vet-practices/suggestions`;

  return fetchAuthSession().then(authSession => {
    return axios
      .post(url, { vet_name: term }, buildAuthHeaders(authSession))
      .then(response => response.data.body);
  });
}

export function postVetClinicSearchWithZip(term, zipcode) {
  const url = `${POLICY_BASE_API}/vet-practices/zipcode-suggestions`;

  return axios
    .post(url, { vet_name: term, zipcode })
    .then(response => response.data.body);
}

export function postCustomVets(vets) {
  const url = `${POLICY_BASE_API}/vet-practices`;

  return fetchAuthSession().then(authSession => {
    return axios
      .post(url, vets, buildAuthHeaders(authSession))
      .then(response => response.data.body);
  });
}

export function submitAnswers(userId, answers) {
  const url = `${POLICY_BASE_API}/users/${userId}/vet-form`;

  return fetchAuthSession().then(authSession => {
    return axios.post(url, answers, buildAuthHeaders(authSession));
  });
}

export function getAnswers(userId) {
  const url = `${POLICY_BASE_API}/users/${userId}/vet-form`;

  return fetchAuthSession().then(authSession => {
    return axios
      .get(url, buildAuthHeaders(authSession))
      .then(response => response.data.body);
  });
}

export const VetsService = {
  searchVetClinic,
  postVetClinicSearchWithZip,
  submitCustomVets: postCustomVets,
  submitAnswers,
  getAnswers,
};
