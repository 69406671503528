// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coverage-dates_dates_5c3e4f5b {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n.coverage-dates_contact_9a720d3f {\n    margin-top: 24px;\n    padding-top: 24px;\n    width: 100%;\n    border-top: 1px solid var(--pusheenGray);\n}\n\n.coverage-dates_contact_9a720d3f button, .coverage-dates_contact_9a720d3f a {\n    color: var(--bluesCluesBlue);\n    font-size: inherit;\n    font-family: inherit;\n    margin: 0;\n    padding: 0;\n    text-decoration: underline;\n    outline: none;\n    border: none;\n    background: none;\n}", "",{"version":3,"sources":["webpack://../../libs/user/src/view/coverage-dates/coverage-dates.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,WAAW;IACX,wCAAwC;AAC5C;;AAEA;IACI,4BAA4B;IAC5B,kBAAkB;IAClB,oBAAoB;IACpB,SAAS;IACT,UAAU;IACV,0BAA0B;IAC1B,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".dates {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n.contact {\n    margin-top: 24px;\n    padding-top: 24px;\n    width: 100%;\n    border-top: 1px solid var(--pusheenGray);\n}\n\n.contact button, .contact a {\n    color: var(--bluesCluesBlue);\n    font-size: inherit;\n    font-family: inherit;\n    margin: 0;\n    padding: 0;\n    text-decoration: underline;\n    outline: none;\n    border: none;\n    background: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dates": "coverage-dates_dates_5c3e4f5b",
	"contact": "coverage-dates_contact_9a720d3f"
};
export default ___CSS_LOADER_EXPORT___;
