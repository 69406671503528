import { COUNTRIES } from '@pumpkincare/shared';

export function formatBillingAddressText(address) {
  const countryName = COUNTRIES.filter(country => country.id == address?.country)[0]
    ?.name;
  return `${address?.street_1}${address?.street_2 ? ` ${address?.street_2}` : ''}, ${
    address?.city
  }, ${address?.state_province} ${address?.zipcode}${
    countryName ? ', ' + countryName : ''
  }`;
}
