import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useBooleanInput } from '@pumpkincare/shared';
import {
  Body2,
  Checkbox,
  FileUpload,
  LegalBody,
  LoaderButton,
} from '@pumpkincare/shared/ui';

import ClaimFormAccordion from '../claim-form-accordion';
import {
  RECORDS_LIST,
  RECORDS_SUBMIT_INITIAL_STATE,
  TERMS,
} from './claim-records-submit-shared';
import ClaimsMedicalRecordModal from './claims-medical-record-modal';
import ClaimsTermsModal from './claims-terms-modal';

import styles from './claim-records-submit.css';

import infoIcon from '../info_icon.svg';

function ClaimRecordsSubmit({
  isOpen,
  onSubmit,
  isSubmitting,
  onUploadFile,
  pet,
  isEstimate,
}) {
  const [formData, setFormData] = useState(RECORDS_SUBMIT_INITIAL_STATE);
  const [errors, setErrors] = useState({});
  const [isAcceptedTerms, toggleAcceptTerms] = useBooleanInput(false);
  const [isTermsModalOpen, toggleTermsModal] = useBooleanInput(false);
  const [isMedicalRecordModalOpen, toggleMedicalRecordModal] =
    useBooleanInput(false);

  const isSawOnly = !!pet.wellness.length && !pet.policies.length;

  function handleSubmit() {
    if (!isAcceptedTerms) {
      return setErrors(state => ({
        ...state,
        [TERMS]: 'You must accept the declaration and fraud notice',
      }));
    }

    // pass in uploaded files
    onSubmit({
      records: formData[RECORDS_LIST],
    });
  }

  function deleteError(key) {
    setErrors(state => ({
      ...state,
      [key]: '',
    }));
  }

  function handleChange(event) {
    const { name, value } = event.target || event;

    setFormData(state => ({
      ...state,
      [name]: value,
    }));

    deleteError(name);
  }

  function handleOpenTermsModal(e) {
    e.stopPropagation();
    e.preventDefault();

    toggleTermsModal();
  }

  function handleOpenMedicalRecordModal(e) {
    e.stopPropagation();
    e.preventDefault();

    toggleMedicalRecordModal();
  }

  function handleCheckTerms(e) {
    toggleAcceptTerms(e);
    deleteError(TERMS);
  }

  return (
    <ClaimFormAccordion isOpen={isOpen} title='Medical Records & Submit'>
      <div className={styles.root}>
        <Body2 isBold style={{ marginBottom: '8px' }}>
          Upload {pet.name}'s Latest Medical Records (Optional)
        </Body2>
        <Body2>
          {isSawOnly
            ? `Share ${pet.name}'s medical records to gain a comprehensive understanding of ${pet.genderGrammar.possessive} health condition. This information will enable us to better assess ${pet.genderGrammar.possessive} overall well-being.`
            : `
            Speed up ${pet.name}’s ${isEstimate ? 'estimate' : 'claim'} by including
            medical records from this visit or past visits that you have not yet shared
            with Pumpkin.
          `}
        </Body2>

        <Body2
          role='button'
          className={styles.modalButton}
          onClick={handleOpenMedicalRecordModal}
          data-testid='medicalRecordsInfo'
        >
          <img src={infoIcon} alt='' />
          What are medical records?
        </Body2>

        <FileUpload
          files={formData[RECORDS_LIST]}
          classes={{
            root: classNames(styles.fileUpload, {
              [styles.fileError]: errors[RECORDS_LIST],
            }),
          }}
          onFileListChange={value => handleChange({ name: RECORDS_LIST, value })}
          onError={message => {
            if (message) {
              setErrors(errors => ({
                ...errors,
                [RECORDS_LIST]: message,
              }));
            }
          }}
          error={errors[RECORDS_LIST]}
          onUpload={(file, rawFile) => {
            deleteError(RECORDS_LIST);
            return onUploadFile(file, rawFile, 'medical-record');
          }}
        />

        <div className={styles.divider} />

        <Checkbox
          label={
            <span>
              I have read and accept the{' '}
              <span
                role='button'
                className={styles.declaration}
                onClick={handleOpenTermsModal}
              >
                declaration and fraud notice.
              </span>
            </span>
          }
          onChange={handleCheckTerms}
          checked={isAcceptedTerms}
          classes={{ root: styles.termsCheckbox }}
          isError={!!errors[TERMS]}
        />

        {errors[TERMS] ? (
          <LegalBody className={styles.error} data-testid='terms-error'>
            {errors[TERMS]}
          </LegalBody>
        ) : null}

        <LoaderButton
          isLoading={isSubmitting}
          onClick={handleSubmit}
          color='primary'
        >
          Submit
        </LoaderButton>
      </div>

      {isMedicalRecordModalOpen ? (
        <ClaimsMedicalRecordModal handleClose={toggleMedicalRecordModal} />
      ) : null}
      {isTermsModalOpen ? <ClaimsTermsModal handleClose={toggleTermsModal} /> : null}
    </ClaimFormAccordion>
  );
}

ClaimRecordsSubmit.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isEstimate: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  pet: PropTypes.object.isRequired,
};

export default ClaimRecordsSubmit;
