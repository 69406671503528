export function convertCentsToDollars(value) {
  return Number.isNaN(value) ? value : value / 100;
}

export function convertDollarsToCents(value) {
  return Number.isNaN(value) ? value : value * 100;
}

export function formatCurrency(
  value,
  { hideZero = false, areCents = false, ifNaN = '' } = {}
) {
  const float = parseFloat(value);
  if (Number.isNaN(float)) {
    return ifNaN;
  }

  const number = areCents ? float / 100 : float;
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: hideZero && Number.isInteger(number) ? 0 : 2,
  });

  return numberFormat.format(number);
}

export function getNumbersFromString(value) {
  const regex = /\d+/g;
  return regex.exec(value);
}

export function isValidCurrencyString(value) {
  const regex = /^(?=.*\d)\d+(\.\d{2})?$/;
  return regex.exec(value);
}

/*
  this should be used as a value setter onChange
  it will prevent your currency input from having more than 2 decimals
 */
export function handleChangeCurrencyDecimals(e, onChange) {
  const { value } = e.target;
  const regex = /^\d+(\.\d{0,2})?$/;

  if (value === '' || regex.test(value)) {
    onChange(e);
  }
}

/*
  this should be used as a value setter onBlur
  it will append 1 or 2 0s to the decimals if the input is missing them
 */
export function handleChangeCurrencyAppendDecimals(e, onChange) {
  const { value } = e.target;

  if (value) {
    e.target.value = parseFloat(value).toFixed(2);
  }

  onChange(e);
}
