import { useMutation } from 'react-query';

import { postMedicalHistory } from './user-service';

/*
 * @param {string} userId
 * @param {string} petId
 * @param {File[]} fileList
 * fileList object shape: { descripion: string, key: string, name: string, type: string, vet_id: string }
 */
export function useMutateMedicalHistory() {
  return useMutation(({ userId, petId, fileList }) => {
    return postMedicalHistory(userId, petId, fileList);
  });
}
