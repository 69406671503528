import { useMutation, useQueryClient } from 'react-query';

import { PET_INCIDENTS_QUERY } from '@pumpkincare/user';

import { transformClaimFormData, transformClaimPayload } from './claim-utils';
import { CLAIMS_QUERY } from './claims-query';
import { postClaim, postClaimV2 } from './claims-service';

export function useFileClaim() {
  const queryClient = useQueryClient();

  return useMutation(
    claim => postClaim(transformClaimPayload(claim)),

    {
      // Always re fetch after error or success
      onSettled: () => queryClient.invalidateQueries([CLAIMS_QUERY]),
    }
  );
}

export function useFileClaimV2(petId) {
  const queryClient = useQueryClient();

  return useMutation(
    claim => postClaimV2(transformClaimFormData(claim)),

    {
      // Always re fetch after error or success
      onSettled: () => {
        queryClient.invalidateQueries([CLAIMS_QUERY]);
        queryClient.invalidateQueries([PET_INCIDENTS_QUERY, petId]);
      },
    }
  );
}
