import { useQuery } from 'react-query';

import { ONE_HOUR } from '@pumpkincare/shared';

import { getUserPetPhotos } from './user-service';

export const USER_ALL_PET_PHOTOS_QUERY = 'userAllPetPhotosQuery';

export function useUserAllPetPhotos(options = {}) {
  return useQuery(
    [USER_ALL_PET_PHOTOS_QUERY],

    () => getUserPetPhotos(),

    {
      placeholderData: [
        {
          id: '',
          pet_id: '',
          location: '',
        },
      ],
      staleTime: ONE_HOUR,
      ...options,
    }
  );
}
