const analyticsQueue = {
  isReady: false,
  tracks: [],
  identifies: [],
  pages: [],
};

export function getAnonymousId() {
  return window.analytics.user?.().anonymousId();
}

export function setAnonymousId(id) {
  window.analytics.setAnonymousId?.(id);
}

export function analyticsTrack(payload, customProperties) {
  if (analyticsQueue.isReady) {
    const { event, ...rest } = payload;
    const properties = {
      ...customProperties,
      ...rest,
    };

    window.analytics.track(event, properties);
  } else {
    analyticsQueue.tracks.push({ payload, customProperties });
  }
}

export function analyticsIdentify(traits) {
  if (analyticsQueue.isReady) {
    window.analytics.identify(getAnonymousId(), traits);
  } else {
    analyticsQueue.identifies.push(traits);
  }
}

export function analyticsPage(traits) {
  if (analyticsQueue.isReady) {
    window.analytics.page(getAnonymousId(), traits);
  } else {
    analyticsQueue.pages.push(traits);
  }
}

export function runQueuedAnalytics() {
  analyticsQueue.isReady = true;

  analyticsQueue.tracks.forEach(({ payload, customProperties }) => {
    analyticsTrack(payload, customProperties);
  });
  analyticsQueue.identifies.forEach(traits => {
    analyticsIdentify(traits);
  });
  analyticsQueue.pages.forEach(traits => {
    analyticsPage(traits);
  });

  analyticsQueue.complete = [analyticsQueue.tracks, analyticsQueue.identifies];
  analyticsQueue.tracks = [];
  analyticsQueue.identifies = [];
}

export function trackPhotoCardGalleryModal(visitId) {
  analyticsTrack({
    event: 'Open Photo Card Modal',
    category: 'Photo Card Gallery',
    label: `Visit: ${visitId}`,
  });
}

export function trackPhotoCardGalleryDownload(visitId, type) {
  analyticsTrack({
    event: 'Download Photo Card Image',
    category: 'Photo Card Gallery',
    label: `Visit: ${visitId}, Type: ${type}`,
  });
}

export function trackViewPreview(category, label) {
  analyticsTrack({
    event: 'View Content Preview',
    category,
    label,
  });
}

export function trackMultiEmailModal(category, label) {
  analyticsTrack({
    event: 'Email to Clients',
    category,
    label,
  });
}

export function trackMultiEmailSend(category, label) {
  analyticsTrack({
    event: 'Send',
    category,
    label,
  });
}

export function trackMultiEmailSuccess(category, label) {
  analyticsTrack({
    event: 'Email Content Succes',
    category,
    label,
  });
}

export function trackSocialDropdown(category, label) {
  analyticsTrack({
    event: 'Share to Social Dropdown',
    category,
    label,
  });
}

export function trackShareToSocial(category, label, channel) {
  analyticsTrack({
    event: 'Share to Social Channel',
    category,
    label: `Label: ${label}, Channel: ${channel}`,
  });
}

export function trackCustomerPEP(action, version, area) {
  analyticsTrack({
    event: `${action} | PEP`,
    category: 'PEP',
    label: `${area ? `${area}, ` : ''}PEP${version}`,
  });
}
