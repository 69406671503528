import { useQuery } from 'react-query';

import { ONE_DAY } from '@pumpkincare/shared';

import { getUserAddresses } from './user-service';

export const getUserMailingAddress = state => state.mailing;
export const getUserBillingAddress = state => state.billing;
export const getUserRatingAddress = state => state.rating;

export const USER_ADDRESSES_QUERY = 'userAddresses';

export function useUserAddresses(options = {}) {
  return useQuery(
    [USER_ADDRESSES_QUERY],

    () => getUserAddresses(),

    {
      placeholderData: {
        mailing: {},
        billing: {},
        rating: {},
      },
      staleTime: ONE_DAY,

      ...options,
    }
  );
}
