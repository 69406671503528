import {
  calculateAndFormatISODate,
  currentISODate,
  dateIsBefore,
} from '@pumpkincare/shared';

const ACTIVE = 'activePets';
const INACTIVE = 'inactivePets';

/**
 * Do not export from libs code - exported for internal libs/user use only
 * */
export const STATUS = {
  ACTIVE: 'active',
  RENEW_PENDING: 'renew_pending',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  VOIDED: 'voided',
  DECLINED: 'declined',
  PENDING: 'pending',
  LAPSED: 'lapsed',
  CANCEL_PENDING: 'cancel_pending',
};

// this map presumes that STATUS enums between SAW and INS are identical
const STATUS_TYPE_MAP = {
  [STATUS.ACTIVE]: ACTIVE,
  [STATUS.RENEW_PENDING]: ACTIVE,
  [STATUS.CANCELLED]: INACTIVE,
  [STATUS.DECLINED]: ACTIVE,
  [STATUS.PENDING]: ACTIVE,
  [STATUS.LAPSED]: ACTIVE,
  [STATUS.CANCEL_PENDING]: INACTIVE,
  [STATUS.VOIDED]: INACTIVE,
  [STATUS.EXPIRED]: INACTIVE,
};

const STATUS_MAP = {
  [STATUS.CANCELLED]: 'Cancelled',
  [STATUS.CANCEL_PENDING]: 'Cancelled',
  [STATUS.VOIDED]: 'Cancelled',
  [STATUS.EXPIRED]: 'Cancelled',
  [STATUS.ACTIVE]: 'Active',
  [STATUS.RENEW_PENDING]: 'Active',
  [STATUS.LAPSED]: 'Billing Lapsed',
  [STATUS.DECLINED]: 'Renewal Declined',
  [STATUS.PENDING]: 'Pending',
};

export function getPetStatusCopy(products) {
  if (isDeclinedAndCancelled(products[0].status, products)) {
    return STATUS_MAP[STATUS.CANCELLED];
  }

  return STATUS_MAP[products[0].status];
}

/**
 * Do not export from libs code - exported for internal libs/user use only
 * */
export function isDeclinedAndCancelled(status, products) {
  return (
    status === STATUS.DECLINED &&
    products.some(product => product.status === STATUS.CANCELLED)
  );
}

export function getProductIsPending(products) {
  const pendingProduct = products.find(policy => policy.status === STATUS.PENDING);
  const isPendingAndFirstDay =
    pendingProduct &&
    dateIsBefore(
      currentISODate(),
      calculateAndFormatISODate(pendingProduct.created_at, '+24h', {
        format: 'YYYY-MM-DDTHH:mm:ss',
      })
    );

  return {
    isPending: !!pendingProduct,
    isPendingAndFirstDay,
  };
}

export function getPetsByStatus(pets) {
  return pets
    .sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    })
    .reduce(
      (result, pet) => {
        const allProducts = [pet.policies, pet.wellness].filter(
          product => product.length
        );

        const isActive = allProducts.some(products => {
          const { status } = products[0];

          if (STATUS_TYPE_MAP[status]) {
            const mappedStatus = isDeclinedAndCancelled(status, products)
              ? INACTIVE
              : STATUS_TYPE_MAP[status];

            return mappedStatus === ACTIVE;
          } else {
            // eslint-disable-next-line no-console
            console.error(`Status ${status} should be accounted for`);
            return false;
          }
        });

        result[isActive ? ACTIVE : INACTIVE].push(pet);
        return result;
      },
      { [ACTIVE]: [], [INACTIVE]: [] }
    );
}
