import PropTypes from 'prop-types';

import {
  FORMATTED_PARENTHESES_PHONE_NUMBER,
  openGladly,
  PHONE_NUMBER_LINK,
} from '@pumpkincare/shared';
import { Body2, Calendar, LegalBody } from '@pumpkincare/shared/ui';

import styles from './coverage-dates.css';

function CoverageDates({ dates, subtitle, canContact, phone }) {
  return (
    <>
      <Calendar style={{ marginBottom: '24px' }} />
      <div className={styles.dates}>
        {dates.map(dateObj => (
          <div key={dateObj.title}>
            <Body2>{dateObj.title}</Body2>

            <LegalBody isBold style={{ margin: '4px 0 2px' }}>
              {dateObj.date}
            </LegalBody>

            <LegalBody>{dateObj.subtitle}</LegalBody>
          </div>
        ))}
      </div>

      {subtitle ? (
        <LegalBody className={styles.contact}>{subtitle}</LegalBody>
      ) : null}

      {canContact && !subtitle ? (
        <LegalBody className={styles.contact}>
          Need to make changes to your plan?{' '}
          <button onClick={openGladly}>Chat</button> or{' '}
          <a href={phone.link}>Call {phone.copy}</a> before the renewal date
        </LegalBody>
      ) : null}
    </>
  );
}

CoverageDates.defaultProps = {
  canContact: false,
  subtitle: '',
  phone: {
    copy: FORMATTED_PARENTHESES_PHONE_NUMBER,
    link: PHONE_NUMBER_LINK,
  },
};

CoverageDates.propTypes = {
  canContact: PropTypes.bool,
  subtitle: PropTypes.string,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
    })
  ).isRequired,
  phone: PropTypes.shape({
    copy: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
};

export default CoverageDates;
