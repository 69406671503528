import {
  calculateAndFormatISODate,
  formatISODate,
  isPuppyPrevent,
} from '@pumpkincare/shared';

import { getLatestRelevantPlan, PEP_PLAN_STATUS } from './plan-config-utils';

const DEFAULT_RETURN = { dates: [], canContact: false, reminder: '' };

/*
  This is highly dependent on reverseSortPlans happening at the user-utils level
 */
export function getPlanDates(plans) {
  if (!plans.length) return DEFAULT_RETURN;

  const latestPlan = plans[0];
  const latestRelevantPlan = getLatestRelevantPlan(plans, {
    isSorted: true,
  });

  // pending on enrollment or after renewal
  if (latestPlan.status === PEP_PLAN_STATUS.PENDING) return DEFAULT_RETURN;

  if (
    [PEP_PLAN_STATUS.ACTIVE, PEP_PLAN_STATUS.LAPSED].some(
      status => status === latestRelevantPlan.status
    )
  ) {
    // isFirstYear will check that the oldest plan is not active - this accounts for renewal plans in the 70 day window before renewal
    const isFirstYear = [PEP_PLAN_STATUS.ACTIVE, PEP_PLAN_STATUS.LAPSED].some(
      status => status === plans[plans.length - 1].status
    );
    const isDeclined = latestPlan.status === PEP_PLAN_STATUS.DECLINED;

    const dates = [
      {
        title: 'Preventive Essentials Start Date',
        date: formatISODate(latestRelevantPlan.plan_effective_date, {
          format: 'MMM D, YYYY',
          inputTimezone: 'local',
        }),
        subtitle: 'Wellness benefits kicked in',
      },
    ];

    if (!isDeclined) {
      dates.push({
        title: 'Preventive Essentials Renewal Date',
        date: calculateAndFormatISODate(
          latestRelevantPlan.plan_effective_date,
          '+1y',
          {
            format: 'MMM D, YYYY',
            inputTimezone: 'local',
          }
        ),
        subtitle: 'Yearly wellness benefits auto renew',
      });
    } else {
      dates.push({
        title: 'Preventive Essentials Cancellation Date',
        date: formatISODate(latestRelevantPlan.plan_end_date, {
          format: 'MMM D, YYYY',
          inputTimezone: 'local',
        }),
        subtitle: 'Wellness benefits end',
      });
    }

    return {
      dates,
      canContact: !isFirstYear && !isDeclined,
      subtitle: isFirstYear
        ? isPuppyPrevent(latestRelevantPlan.plan)
          ? 'Remember, you can file a claim for any eligible expenses received at the vet before enrolling your puppy!'
          : 'Remember, you can file claims for any eligible services received at the vet up to 14 days before enrolling!'
        : '',
    };
  }

  if (
    [PEP_PLAN_STATUS.CANCELLED, PEP_PLAN_STATUS.EXPIRED].some(
      status => status === latestRelevantPlan.status
    ) ||
    [PEP_PLAN_STATUS.VOIDED, PEP_PLAN_STATUS.CANCEL_PENDING].some(
      status => status === latestPlan.status || status === latestRelevantPlan.status
    )
  ) {
    return {
      dates: [
        {
          title: 'Preventive Essentials Coverage End Date',
          date: formatISODate(latestRelevantPlan.plan_end_date, {
            format: 'MMM D, YYYY',
            inputTimezone: 'local',
          }),
          subtitle: 'Wellness benefits end',
        },
      ],
      canContact: false,
    };
  }

  return { dates: [], canContact: false, subtitle: '' };
}
