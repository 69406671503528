export function transformAttachment(file, signedUrls) {
  const formData = new FormData();
  const signedUrlParams = signedUrls[file.name];

  file.key = signedUrlParams.fields.key;
  Object.keys(signedUrlParams.fields).forEach(key => {
    formData.append(key, signedUrlParams.fields[key]);
  });

  // Actual file has to be appended last.
  formData.append('file', file);

  return [signedUrlParams.url, formData];
}
