import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import ClaimConditionsServices from './claim-conditions-services';
import ClaimRecordsSubmit from './claim-records-submit';
import ClaimVisitDetails from './claim-visit-details';

import styles from './claim-form.css';

// Prop-drilling from parent so that this can be used in MC or PawPortal
function ClaimForm({
  conditions,
  incidents,
  pet,
  zipcode,
  onSubmit,
  isSubmitting,
  isProcessingFile,
  onUploadFile,
  isEstimate,
}) {
  const [step, setStep] = useState(0);
  const formData = useRef({});
  const formRef = useRef();

  function handleSubmit(data) {
    formData.current = { ...formData.current, ...data };

    if (step !== 2) {
      setStep(state => state + 1);
      window.scrollTo({ top: formRef.current.offsetTop, behavior: 'smooth' });
      return;
    }

    onSubmit(formData);
  }

  return (
    <div className={styles.root} ref={formRef}>
      <ClaimVisitDetails
        pet={pet}
        zipcode={zipcode}
        isOpen={step === 0}
        canEdit={step > 0}
        onSubmit={handleSubmit}
        onEditClick={() => setStep(0)}
        isComplete={!!formData.current.visit}
        isProcessingClaimAttachment={isProcessingFile}
        onUploadFile={onUploadFile}
      />

      <ClaimConditionsServices
        pet={pet}
        conditions={conditions}
        incidents={incidents}
        isOpen={step === 1}
        canEdit={step > 1}
        onSubmit={handleSubmit}
        onEditClick={() => setStep(1)}
        isComplete={!!formData.current.conditions}
      />

      <ClaimRecordsSubmit
        isOpen={step === 2}
        pet={pet}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onUploadFile={onUploadFile}
        isEstimate={isEstimate}
      />
    </div>
  );
}

ClaimForm.defaultProps = {
  conditions: [],
  incidents: [],
  zipcode: '',
};

ClaimForm.propTypes = {
  conditions: PropTypes.array,
  isSubmitting: PropTypes.bool,
  isProcessingFile: PropTypes.bool,
  isEstimate: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
  pet: PropTypes.object.isRequired,
  zipcode: PropTypes.string,
};

export default ClaimForm;
