import {
  getQuoteEmail,
  getQuotePets,
  getQuotePolicyZipCode,
} from '@pumpkincare/quotes';
import {
  calculateAndFormatISODate,
  captureException,
  dateIsAfter,
  dateIsBefore,
  dateIsBetween,
  DOG,
  formatFloat,
  getIsLoggedIn,
  getIsPuppy,
  isPuppyPrevent,
  UP_TO_5_MONTHS,
  UP_TO_11_MONTHS,
} from '@pumpkincare/shared';

import {
  ANNUAL_LIMIT,
  COPAY,
  DEDUCTIBLE,
  UNLIMITED_ANNUAL_LIMIT,
} from './constants';

const ACTIVE_STATUSES = ['active', 'pending', 'renew_pending'];

export function canGoToPlanPage(quoteData = {}) {
  const loggedIn = getIsLoggedIn();
  const petsData = getQuotePets(quoteData);
  const emailData = getQuoteEmail(quoteData);
  const zipcodeData = getQuotePolicyZipCode(quoteData);
  const hasPetGender = petsData?.some(pet => pet.petGender);
  const hasBreeds = petsData?.some(pet => pet.petBreedCode);

  return loggedIn ? hasPetGender && hasBreeds : emailData && zipcodeData;
}

export function calculatePlanPrice(
  activePet,
  selectedPolicyPrice,
  preventativePrice
) {
  let price = parseFloat(selectedPolicyPrice);

  if (activePet.preventativeSelected) {
    price = price + parseFloat(preventativePrice);
  }

  return formatFloat(price);
}

export function formatLevers({ copays, deductibles, limits, prices }, getPrice) {
  return {
    prices,
    levers: {
      copays,
      limits,
      deductibles: deductibles.map(deductible => {
        return {
          ...deductible,
          value: getPrice(prices, deductible.option.value),
        };
      }),
    },
  };
}

export function formatLeversTitle(availableLevers) {
  const leversMap = {
    copays: 'reimbursement rate',
    limits: 'annual limit',
    deductibles: 'annual deductible',
  };
  const order = ['copays', 'limits', 'deductibles'];

  const titles = order
    .filter(lever => availableLevers[lever])
    .map(lever => leversMap[lever]);

  switch (titles.length) {
    case 1:
      return `Recommended ${titles[0]}:`;
    case 2:
      return `Recommended ${titles[0]} & ${titles[1]}:`;
    case 3:
      return `Recommended ${titles[0]}, ${titles[1]} & ${titles[2]}:`;
    default:
      return `Recommended:`;
  }
}

export function formatRecommendations(unformattedRecommendations) {
  return Object.keys(unformattedRecommendations).reduce((result, key) => {
    const { id } = unformattedRecommendations[key];

    result[key] = { id, value: id.replace(/\D/g, '') };

    return result;
  }, {});
}

function getHasActivePlan(item) {
  return ACTIVE_STATUSES.some(status => status === item.status);
}

export function getActivePlan(plans = []) {
  return plans.find(plan => getHasActivePlan(plan));
}

export function hasCustomPlanDoc(plan) {
  return plan.version_number > 3;
}

function getEntitlementUtilization(entitlement, utilization) {
  const entitlementInfo = utilization.find(item => item.entitlement === entitlement);
  if (!entitlementInfo) {
    return null;
  }

  return {
    utilized: entitlementInfo.utilized,
    total:
      entitlementInfo.available + entitlementInfo.pending + entitlementInfo.utilized,
  };
}

export function getFecalExamUtilization(utilization) {
  return getEntitlementUtilization('Fecal Exams', utilization);
}

export function getHeartwormTestUtilization(utilization) {
  const heartworm = getEntitlementUtilization('Heartworm Exams', utilization);
  if (heartworm) {
    return heartworm;
  }

  return getEntitlementUtilization('Vector Borne Exams', utilization);
}

export function getIsLegacyPlan(plan) {
  return plan.version_number <= 2;
}

export function getLegacyPlanItems(planVersion, species, isPuppy) {
  let planItems = [];

  switch (planVersion) {
    case 1:
      if (species === DOG) {
        planItems = [
          {
            item: 'Tick & Flea Protection',
            subtext:
              'Simparica<sup>®</sup>(sarolaner) Chewables<br/>(6-month supply, shipped 2x per year)',
          },
          { item: 'Annual Vaccines', subtext: 'Up to 3 vaccines per year' },
        ];
      } else {
        planItems = [
          {
            item: '6-in-1 Parasite Protection',
            subtext:
              'Revolution<sup>®</sup> Plus (selamectin and sarolaner topical solution)<br/>(6-month supply, shipped 2x per year)',
          },
          { item: 'Annual Vaccines', subtext: '1 vaccine per year' },
        ];
      }
      break;

    case 2:
      if (isPuppy) {
        planItems = [
          {
            item: 'Monthly Parasite Protection',
            subtext:
              'Simparica Trio<sup>™</sup>(sarolaner, moxidectin, and pyrantel chewable tablets): 6-month supply, shipped 2x per year',
          },
          { item: '4 Puppy Vaccines' },
        ];
      } else if (species === DOG) {
        planItems = [
          {
            item: 'Year of Parasite Protection',
            subtext:
              'Simparica Trio<sup>™</sup>(sarolaner, moxidectin, and pyrantel chewable tablets): 6-month supply, shipped 2x per year',
          },
          { item: '2 Annual Vaccines' },
          { item: '1 Annual Wellness Exam Fee' },
        ];
      } else {
        planItems = [
          {
            item: 'Year of 6-in-1 Parasite Protection',
            subtext:
              'Revolution<sup>®</sup> Plus (selamectin and sarolaner topical solution): 6-month supply, shipped 2x per year',
          },
          { item: '1 Annual Vaccine' },
          { item: '1 Annual Wellness Exam Fee' },
        ];
      }
      break;
  }

  return planItems;
}

export function getPetInsuranceCost(pet, prices, unformattedRecommendations) {
  const recommendations = formatRecommendations(unformattedRecommendations);

  const activeValues = {
    deductible: pet.deductible || recommendations.deductible.value,
    copay: pet.copay || recommendations.copay.value,
    annual_limit: pet.annual_limit || recommendations.annual_limit.value,
  };

  return prices[`Copay${activeValues.copay}`][`Limit${activeValues.annual_limit}`][
    `Deductible${activeValues.deductible}`
  ];
}

export function getPlanForPolicyPeriod(plans, startDate, endDate) {
  if (!plans || !startDate || !endDate) {
    return null;
  }

  const filtered = plans.filter(plan =>
    dateIsBetween(plan.plan_effective_date, startDate, endDate)
  );

  return filtered.length == 0 ? null : filtered[filtered.length - 1];
}

export function getVaccinesUtilization(utilization) {
  return getEntitlementUtilization('Vaccines', utilization);
}

export function getWellnessUtilization(utilization) {
  return getEntitlementUtilization('Office Visits', utilization);
}

export function isActivePlanPrevent2(pet) {
  const activePlan = getActivePlan(pet.plans) || { plan: {} };
  return isPrevent2(activePlan.plan);
}

export function isPrevent1(plan) {
  return Number(plan?.version_number) === 1;
}

export function isPrevent2(plan) {
  return Number(plan?.version_number) === 2;
}

export function isPrevent3(plan) {
  return Number(plan?.version_number) >= 3 && Number(plan?.version_number) < 4;
}

export function isPrevent4(plan) {
  return Number(plan?.version_number) >= 4;
}

export function normalizePlanDetails(descriptionPlanDetails) {
  return descriptionPlanDetails.map(item => ({ item }));
}

export function parsePlanDetails(plan, species) {
  if (getIsLegacyPlan(plan)) {
    const isPuppy = isPuppyPrevent(plan);
    return getLegacyPlanItems(plan.version_number, species, isPuppy);
  }

  return plan.entitlements.flatMap(({ items, id }) => {
    return items.map(item => {
      return {
        id: id,
        item: formatEntitlement(item, { isShort: true }),
      };
    });
  });
}

export function transformPetAgeToMonths(petAge) {
  return petAge === UP_TO_5_MONTHS
    ? 5
    : petAge === UP_TO_11_MONTHS
    ? 11
    : parseInt(petAge) * 12;
}

export function transformPolicyOptions({ prices, copays, deductibles, limits }) {
  /*
    if lever has more than one option, it'll be selectable and need option object for rendering
    otherwise, don't map and add option
   */
  return {
    prices,
    copays:
      copays.length > 1 ? copays.map(copay => addOption(copay, 'copay')) : copays,
    deductibles:
      deductibles.length > 1
        ? deductibles.map(deductible => addOption(deductible, 'deductible'))
        : deductibles,
    limits:
      limits.length > 1
        ? limits.map(limit => addOption(limit, 'annual_limit'))
        : limits,
  };
}

function addOption(item, key) {
  return {
    ...item,
    option: {
      key,
      value:
        item.id === 'LimitUnlimited'
          ? item.id.replace(/Limit/, '')
          : item.id.replace(/\D/g, ''),
    },
  };
}

/*
  recommendations returns like:
  [
    { type: copay, section: recommended_plan, property: description, value: 90% cash back... },
    { type: annual_limit, section: recommended_plan, property: description, value: A smart... },
    { type: deductible, section: recommended_plan, property: description, value: Paying... },
    ...
    { type: copay, section: recommendations, property: id, value: Copay10 },
    { type: copay, section: recommendations, property: title, value: 90% },
    { type: copay, section: recommendations, property: subtitle, value: Reimbursement Rate },
    ...
    { type: deductible, section: why_do_we_recommend, property: item, value: $250 Annual D... },
    { type: deductible, section: why_do_we_recommend, property: reason, value: to help min... },
    { type: preventive, section: why_do_we_recommend, property: item, value: Preventive... },
    { type: preventive, section: why_do_we_recommend, property: reason, value: to help cover... },
  ]

  this will transform it to be more readable and easily mappable elsewhere:
  {
    recommended_plan: {
      copay: { title: 90%, subtitle: Reimbursement Rate, description: 90% cash back..., },
      annual_limit: { ... },
      deductible: { ... },
    },
    recommendations: { ... },
    why_do_we_recommend: { ... },
 */
export function transformRecommendations(data) {
  const recommendationObject = data.reduce((result, entry) => {
    result[entry.section] = result[entry.section] || {};
    result[entry.section][entry.type] = result[entry.section][entry.type] || {};
    result[entry.section][entry.type][entry.property] = entry.value;

    return result;
  }, {});

  const { recommendations, why_do_we_recommend, recommended_plan } =
    recommendationObject;
  const sections = [COPAY, ANNUAL_LIMIT, DEDUCTIBLE];

  // FE will be able to tell if a white screen is caused by corrupted data
  if (
    sections.some(
      section => !(recommendations[section] && recommendations[section].id)
    )
  ) {
    captureException('Section recommendations missing data');
  }

  if (
    sections.some(
      section =>
        !(
          recommended_plan[section] &&
          recommended_plan[section].description &&
          recommended_plan[section].title &&
          recommended_plan[section].subtitle
        )
    )
  ) {
    captureException('Section recommended_plan missing data');
  }

  if (
    sections.some(
      section =>
        !(
          why_do_we_recommend[section] &&
          why_do_we_recommend[section].item &&
          why_do_we_recommend[section].reason
        )
    )
  ) {
    captureException('Section why_do_we_recommend missing data');
  }

  return recommendationObject;
}

export function zeroesToK(value) {
  return String(
    Math.abs(value) >= 1000 ? Math.round(Number(value) / 1000) + 'K' : value
  );
}

export function isUnlimitedLimit(annualLimit) {
  return annualLimit === UNLIMITED_ANNUAL_LIMIT;
}

export const ENTITLEMENT_NAME = {
  'Revolution Plus': 'Months of 6-in-1 Parasite Protection Shipped to Your Door',
  'Simparica Trio': 'Months of Parasite Protection Shipped to Your Door',
};

export function formatEntitlement(entitlement, { isShort = false }) {
  const copy =
    (isShort && ENTITLEMENT_NAME[entitlement.name]) || entitlement.description;

  return `${entitlement.quantity} ${copy}`;
}

export function mixedPets(quotePets) {
  // Returns true if quote pets contains cross-species
  // pets or a puppy and an adult dog
  const unique = [
    ...new Set(
      quotePets.map(item => {
        if (getIsPuppy(item)) return 'Puppy';
        return item.petBreedSpecies;
      })
    ),
  ];

  return unique.length > 1;
}

export function getPlanForDate(plans, dateStr) {
  return plans.find(
    plan =>
      dateIsAfter(
        dateStr,
        calculateAndFormatISODate(plan.plan_effective_date, '-15d')
      ) && dateIsBefore(dateStr, plan.plan_end_date)
  );
}
