import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, ButtonStyles, Modal } from '@pumpkincare/shared/ui';

import styles from './claims-medical-record-modal.css';

import invoiceExample from '../../invoice_example.svg';
import phoneDogGraphic from './dog_phone.png';
import medRecordExample from './med_record_example.svg';

function ClaimsMedicalRecordModal({ handleClose }) {
  return (
    <Modal
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      classes={{ content: styles.modalContainer }}
    >
      <h3 id='modal-title'>What is a medical record and how do I get it?</h3>
      <div className={styles.primaryContent}>
        <div className={styles.textImageSpacer}>
          <div>
            <Body2 id='modal-description' isBold className={styles.title}>
              What is a medical record?
            </Body2>
            <Body2>
              A medical record (often called SOAP Notes) is a full report of your
              pet's health history. It documents all signs, symptoms, lab work,
              diagnoses, & treatments received at the vet.
            </Body2>
          </div>
          <img src={medRecordExample} alt='' className={styles.exampleImages} />
        </div>

        <div className={styles.textImageSpacer}>
          <div>
            <Body2 isBold className={styles.title}>
              How is a medical record different from an invoice?
            </Body2>
            <Body2>
              An invoice or vet bill is a itemized list of veterinary services owed
              and a statement of the total amount due. Both invoices and medical
              records are necessary to provide to process claims.
            </Body2>
          </div>
          <img src={invoiceExample} alt='' className={styles.exampleImages} />
        </div>
      </div>

      <div className={styles.questionContainer}>
        <div>
          <Body2 isBold className={styles.title}>
            How do I get Medical Records from my vet?
          </Body2>
          <Body2>
            If you have a new pet and haven’t taken them to the vet yet, ask your
            adoption facility or breeder for your pet’s medical records.
          </Body2>
          <Body2>
            If you already have a primary vet, ask them to share a digital copy of
            your pet’s medical records, often referred to as SOAP Notes.{' '}
          </Body2>
        </div>
        <img src={phoneDogGraphic} alt='' className={styles.dogGraphic} />
      </div>

      <button
        className={classNames(ButtonStyles.secondary, styles.closeButton)}
        onClick={handleClose}
        type='button'
      >
        Close
      </button>
    </Modal>
  );
}

ClaimsMedicalRecordModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ClaimsMedicalRecordModal;
