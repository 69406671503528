export const ACCIDENT = 'accident';
export const CONDITION_DATE = 'conditionDate';
export const CONDITION_TYPE = 'conditionType';
export const GENERIC = 'generic';
export const ILLNESS = 'illness';
export const WELLNESS = 'wellness';
export const INCIDENTS = 'incidents';
export const VISIT_REASON = 'visitReason';

export const CONDITIONS_SERVICES_INITIAL_STATE = {
  [ACCIDENT]: [],
  [ILLNESS]: [],
  [INCIDENTS]: {},
  [WELLNESS]: false,
  [VISIT_REASON]: '',
};
