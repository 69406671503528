import { useQuery } from 'react-query';
import axios from 'axios';

import { CLOUDFLARE_WORKER_API } from '@pumpkincare/config';

import { EMBARK } from './constants/partners';
import { ONE_DAY, ONE_HOUR, TEN_MINUTES } from './constants/time';
import {
  getEmbarkDiscount,
  getProgramResolver,
} from './cloud-flare-workers-service';
import { getPaperlessAgreement, getPartnerBranding } from './service';

export function useBreeds(species = '') {
  return useQuery(
    ['petBreeds', species],

    () =>
      axios
        .get(`${CLOUDFLARE_WORKER_API}/breeds/${species.toLowerCase()}s`)
        .then(response => response.data),

    {
      enabled: !!species,
      placeholderData: [],
      staleTime: TEN_MINUTES,
    }
  );
}

export function useEmbarkDiscount(stateCode, partner) {
  return useQuery(
    ['embarkDiscount', stateCode],

    () => getEmbarkDiscount(stateCode),

    {
      enabled: partner === EMBARK && !!stateCode,
      placeholderData: { discount: 0 },
      staleTime: ONE_HOUR,
    }
  );
}

export function useProgramResolver(policyState, petSpecies, petAge) {
  return useQuery(
    ['programResolver', policyState, petSpecies, petAge],

    () => getProgramResolver(policyState, petSpecies, petAge),

    {
      enabled: !!policyState,

      placeholderData: {
        sample_plan: '#',
        underwriter: '',
        waiting_periods: {},
      },
      staleTime: ONE_HOUR,
    }
  );
}

export function usePaperlessAgreement(termsVersion) {
  return useQuery(
    ['paperlessAgreement', termsVersion],

    () => getPaperlessAgreement({ terms_version: termsVersion }),

    {
      placeholderData: {
        paperless_terms_url: '',
        terms_version: '',
      },
      staleTime: ONE_DAY,
    }
  );
}

export function usePartnerBranding(partnerCode) {
  return useQuery(
    ['brandingQuery', partnerCode],

    () => getPartnerBranding(partnerCode),

    {
      enabled: !!partnerCode,
      placeholderData: {
        id: '',
        partner_id: '',
        primary_logo_url: '',
        secondary_logo_url: '',
      },
    }
  );
}
