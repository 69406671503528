import { CAT, DOG } from '../constants/breed-species';
import { UP_TO_5_MONTHS, UP_TO_11_MONTHS } from '../constants/pet-ages';
import { VALID_STATES } from '../constants/state-list';
import { currentISODate, dateIsBefore } from './date-utils';

export function validateOnlyNumbers(str, canBeEmpty = false) {
  return (canBeEmpty && str === '') || /^([\d.]+)$/i.test(str);
}

export function validateDateMMDDYYYNotInFuture(dateStr, canBeEmpty = false) {
  return (
    (canBeEmpty && dateStr === '') ||
    (validateDateMMDDYYY(dateStr) && dateIsBefore(dateStr, currentISODate()))
  );
}

export function validateDateMMDDYYYNotBefore(dateStr, beforeDateStr) {
  return (
    validateDateMMDDYYY(dateStr) &&
    validateDateMMDDYYY(beforeDateStr) &&
    !dateIsBefore(dateStr, beforeDateStr)
  );
}

export function validateDateMMDDYYY(dateStr) {
  return /^([1-9]|((0)[1-9])|((1)[0-2]))(\/)([1-9]|((0)[1-9])|([1-2][0-9])|((3)[0-1]))(\/)\d{4}$/i.test(
    dateStr
  );
}

export const validateState = state => !!VALID_STATES[state];

export const checkPasswordRequirementsLegacy = password => {
  const atLeast12Char = /^(?=.{12,}).*$/;
  const atLeastOneLowercase = /^(?=.*[a-z]).*$/;
  const atLeastOneUppercase = /^(?=.*[A-Z]).*$/;
  const atLeastOneDigit = /^(?=.*[0-9]).*$/;
  // eslint-disable-next-line no-useless-escape
  const atLeastOneSpecialChar = /^(?=.*[\^$*.\[\]{}()?\-"!@#%&/\\,><':;|_~`]).*$/;

  let valid =
    atLeast12Char.test(password) &&
    atLeastOneLowercase.test(password) &&
    atLeastOneUppercase.test(password) &&
    atLeastOneDigit.test(password) &&
    atLeastOneSpecialChar.test(password);

  return valid;
};

export function checkPasswordRequirements(password) {
  const atLeast12Char = /^(?=.{12,}).*$/;
  const atLeastOneLowercase = /^(?=.*[a-z]).*$/;
  const atLeastOneUppercase = /^(?=.*[A-Z]).*$/;
  const atLeastOneDigit = /^(?=.*[0-9]).*$/;
  // eslint-disable-next-line no-useless-escape
  const atLeastOneSpecialChar = /^(?=.*[\^$*.\[\]{}()?\-"!@#%&/\\,><':;|_~`]).*$/;

  const hasAtLeast12Char = atLeast12Char.test(password);
  const hasAtLeastOneLowercase = atLeastOneLowercase.test(password);
  const hasAtLeastOneUppercase = atLeastOneUppercase.test(password);
  const hasAtLeastOneDigit = atLeastOneDigit.test(password);
  const hasAtLeastOneSpecialChar = atLeastOneSpecialChar.test(password);
  const valid = {
    hasAtLeast12Char: hasAtLeast12Char,
    hasAtLeastOneLowercase: hasAtLeastOneLowercase,
    hasAtLeastOneUppercase: hasAtLeastOneUppercase,
    hasAtLeastOneDigit: hasAtLeastOneDigit,
    hasAtLeastOneSpecialChar: hasAtLeastOneSpecialChar,
    hasErrors:
      !hasAtLeast12Char ||
      !hasAtLeastOneLowercase ||
      !hasAtLeastOneUppercase ||
      !hasAtLeastOneDigit ||
      !hasAtLeastOneSpecialChar,
  };

  return valid;
}

export function validateEmail(email) {
  return /^[a-zA-Z0-9][\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/.test(
    String(email).toLowerCase()
  );
}

export function getIsPuppy(pet) {
  const { petAge, petBreedSpecies } = pet;

  return (
    petBreedSpecies === DOG &&
    (petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS)
  );
}

export function getIsPuppyForPEP(pet) {
  const { petAge, petBreedSpecies } = pet;

  return petBreedSpecies === DOG && petAge === UP_TO_5_MONTHS;
}

export function getIsKitten(pet) {
  const { petAge, petBreedSpecies } = pet;

  return (
    petBreedSpecies === CAT &&
    (petAge === UP_TO_5_MONTHS || petAge === UP_TO_11_MONTHS)
  );
}

export function getCanGoToCheckout(pets = []) {
  return pets.every(pet => !!(pet.deductible && pet.annual_limit));
}

export function getIsCompletePet(pet) {
  const requiredKeys = [
    'annual_limit',
    'deductible',
    'id',
    'petAge',
    'petBreedCode',
    'petBreedName',
    'petBreedSpecies',
    'petBreedType',
    'petDOB',
    'petGender',
    'petName',
    'preventativeSelected',
  ];

  return requiredKeys.every(key => {
    if (pet[key] === null || pet[key] === undefined) {
      return false;
    }

    if (Array.isArray(pet[key])) {
      return !!pet[key].length;
    } else if (typeof pet[key] === 'object') {
      return !!Object.keys(pet[key]).length;
    }

    return true;
  });
}

export function getIsDeepLinkUrl(pathname) {
  return /\/(plan-selection\/.+)/.test(pathname);
}

export function isCreditCardExpDateValid(expMonth, expYear) {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const year = currentDate.getFullYear();

  if (parseInt(expYear) > year) {
    return true;
  } else if (parseInt(expYear) === year) {
    return parseInt(expMonth) >= month;
  }

  return false;
}
