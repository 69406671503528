/*
  Cookie will be good for a year
  Every browser that supports max-age will ignore the expires regardless of it’s value,
  and likewise, Internet Explorer will ignore the max-age and just use expires.
*/
import { BASE_DOMAIN } from '@pumpkincare/config';

export function createCookieString(name, value, domain, days) {
  const maxAge = 24 * 60 * 60 * days;
  const nameValueStr = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
  const pathStr = ';path=/';
  const maxAgeStr = `;max-age=${maxAge}`;
  const expires = new Date();
  expires.setTime(expires.getTime() + maxAge);
  const expiresStr = `;expires=${expires.toUTCString()}`;
  const domainStr = domain ? `;domain=${domain}` : '';

  return nameValueStr + pathStr + maxAgeStr + expiresStr + domainStr;
}

export function setCookie(name, value, { domain = BASE_DOMAIN, days = 365 } = {}) {
  document.cookie = createCookieString(name, value, domain, days);
}

export function getCookie(name) {
  const v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');

  return v ? v[2] : null;
}

export function removeCookie(name, domain = BASE_DOMAIN) {
  setCookie(name, null, { domain, days: 0 });
}
