import { formatISODate } from '@pumpkincare/shared';

export const PEP_PLAN_STATUS = {
  ACTIVE: 'active',
  RENEW_PENDING: 'renew_pending',
  CANCELLED: 'cancelled',
  EXPIRED: 'expired',
  VOIDED: 'voided',
  DECLINED: 'declined',
  PENDING: 'pending',
  LAPSED: 'lapsed',
  CANCEL_PENDING: 'cancel_pending',
};

/*
  returns the latest relevant plan for a pet - there are generally only two recent plans for a pet owner that are relevant:
  the current one and the renewing one
  this returns the current plan (which can include the canceled / expired pets too)
 */
export function getLatestRelevantPlan(plans) {
  if (!plans.length) return {};

  if (
    [
      PEP_PLAN_STATUS.ACTIVE,
      PEP_PLAN_STATUS.CANCELLED,
      PEP_PLAN_STATUS.CANCEL_PENDING,
      PEP_PLAN_STATUS.EXPIRED,
      PEP_PLAN_STATUS.LAPSED,
      PEP_PLAN_STATUS.VOIDED,
    ].some(status => status === plans[0].status)
  )
    return plans[0];

  return (plans[1]?.status === PEP_PLAN_STATUS.VOIDED && plans[2]) || plans[1];
}

export function getPlanPrice(plans, isAnnuallyCharged) {
  if (!plans.length) return {};

  const { plan } = getLatestRelevantPlan(plans);

  return {
    cost: isAnnuallyCharged ? plan.cost * 12 : plan.cost,
    frequency: isAnnuallyCharged ? 'year' : 'month',
  };
}

export function getPlanEntitlements(plans) {
  if (!plans.length) return [];

  const { plan } = getLatestRelevantPlan(plans);

  return plan.entitlements.flatMap(entitlement => entitlement.items);
}

export function getPlanDocuments(plans) {
  if (!plans.length) return [];

  const allowedStatuses = [
    PEP_PLAN_STATUS.ACTIVE,
    PEP_PLAN_STATUS.CANCELLED,
    PEP_PLAN_STATUS.CANCEL_PENDING,
    PEP_PLAN_STATUS.EXPIRED,
    PEP_PLAN_STATUS.VOIDED,
  ];
  const filteredPlans = plans.filter(plan =>
    allowedStatuses.some(status => status === plan.status)
  );
  return filteredPlans.map(plan => {
    const { documents, id, plan_effective_date, plan_end_date } = plan;
    const document = documents?.[0] || {};

    const agreement = {
      id,
      name: 'Customer Agreement',
      subtitle: `${formatISODate(plan_effective_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })} - ${formatISODate(plan_end_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      })}`,
      processingText: !document?.location
        ? "Your latest customer agreement isn't ready. Hang tight and check back soon."
        : '',
    };

    return agreement;
  });
}
