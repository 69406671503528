import { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  handleChangeCurrencyAppendDecimals,
  handleChangeCurrencyDecimals,
  handleFormatDateKeyUp,
  useBooleanInput,
} from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  Checkbox,
  FileUpload,
  LegalBody,
  Radio,
  RadioGroup,
  TextField,
} from '@pumpkincare/shared/ui';
import { VetClinicSearch } from '@pumpkincare/vets/ui';

import ClaimFormAccordion from '../claim-form-accordion';
import {
  AMOUNT_CLAIMED,
  END_DATE,
  FILE_LIST,
  ONLY_PET,
  START_DATE,
  VET,
  VET_NAME,
  VISIT_DATE,
} from './claim-visit-details-shared';
import { validateClaimVisitDetails } from './claim-visit-details-validations';
import ClaimsInvoiceModal from './claims-visit-invoice-modal';

import styles from './claim-visit-details.css';

import infoIcon from '../info_icon.svg';

function ClaimVisitDetails({
  canEdit,
  isComplete,
  isOpen,
  isProcessingClaimAttachment,
  onUploadFile,
  onSubmit,
  onEditClick,
  pet,
  zipcode,
}) {
  const [formData, setFormData] = useState({
    [FILE_LIST]: [],
    [VISIT_DATE]: '',
    [START_DATE]: '',
    [END_DATE]: '',
    [VET]: {},
    [VET_NAME]: '',
    [AMOUNT_CLAIMED]: '',
    [ONLY_PET]: null,
  });
  const [errors, setErrors] = useState({});
  const [isMultiDay, toggleIsMultiDay] = useBooleanInput(false);
  const [isCustomVet, setIsCustomVet] = useState(false);
  const [isInvoiceModalOpen, toggleInvoiceModal] = useBooleanInput(false);

  function handleSubmit(e) {
    e.preventDefault();

    const invalid = validateClaimVisitDetails({
      data: formData,
      isCustomVet,
      isMultiDay,
    });
    if (Object.keys(invalid).length) {
      return setErrors(invalid);
    }

    const dates = isMultiDay
      ? {
          [START_DATE]: formData[START_DATE],
          [END_DATE]: formData[END_DATE],
        }
      : { [VISIT_DATE]: formData[VISIT_DATE] };
    const vet = isCustomVet
      ? { [VET_NAME]: formData[VET_NAME] }
      : { [VET]: formData[VET] };

    const staticData = {
      [FILE_LIST]: formData[FILE_LIST],
      [AMOUNT_CLAIMED]: formData[AMOUNT_CLAIMED],
      [ONLY_PET]: formData[ONLY_PET],
    };

    onSubmit({
      visit: {
        ...staticData,
        ...dates,
        ...vet,
      },
    });
  }

  function deleteError(key) {
    setErrors(state => ({
      ...state,
      [key]: '',
    }));
  }

  function handleChange(event) {
    const { name, value } = event.target || event;

    setFormData(state => ({
      ...state,
      [name]: value,
    }));

    deleteError(name);
  }

  function handleMultiDayClick() {
    toggleIsMultiDay();

    deleteError(VISIT_DATE);
    deleteError(START_DATE);
    deleteError(END_DATE);

    setFormData(state => ({
      ...state,
      [VISIT_DATE]: isMultiDay ? state[START_DATE] : '',
      [START_DATE]: !isMultiDay ? state[VISIT_DATE] : '',
    }));
  }

  function handleVetSelect(vet) {
    const needsCustomVet = !(vet?.id && vet?.vet_name);
    setIsCustomVet(needsCustomVet);

    setFormData(state => ({
      ...state,
      [VET]: vet,
      [VET_NAME]: '',
    }));
  }

  function handleVetClear() {
    setFormData(state => ({
      ...state,
      [VET]: {},
      [VET_NAME]: '',
    }));

    setIsCustomVet(false);
  }

  function handleVetSelectChange() {
    if (errors[VET]) deleteError(VET);
  }

  function handleInvoiceModal(e) {
    e.stopPropagation();
    e.preventDefault();

    toggleInvoiceModal();
  }

  return (
    <ClaimFormAccordion
      canEdit={canEdit}
      isComplete={isComplete}
      isOpen={isOpen}
      title='Visit Details'
      onEditClick={onEditClick}
    >
      <form className={styles.root} onSubmit={handleSubmit}>
        <div>
          <Body2 isBold>Upload an Invoice</Body2>
          <Body2
            role='button'
            className={styles.modalButton}
            onClick={handleInvoiceModal}
          >
            <img src={infoIcon} alt='' />
            View tips for a clear & readable invoice
          </Body2>
          <FileUpload
            files={formData[FILE_LIST]}
            classes={{
              root: classNames(styles.fileUpload, {
                [styles.fileError]: errors[FILE_LIST],
              }),
            }}
            onFileListChange={value => handleChange({ name: FILE_LIST, value })}
            onError={message => {
              if (message) {
                setErrors(errors => ({
                  ...errors,
                  [FILE_LIST]: message,
                }));
              }
            }}
            error={errors[FILE_LIST]}
            onUpload={(file, rawFile) => {
              deleteError(FILE_LIST);
              return onUploadFile(file, rawFile, 'claim-attachment');
            }}
          />
        </div>

        <div>
          <div
            className={classNames(styles.visitDatesContainer, {
              [styles.singleDate]: !isMultiDay,
            })}
          >
            {isMultiDay ? (
              <>
                <TextField
                  id={START_DATE}
                  name={START_DATE}
                  value={formData[START_DATE]}
                  onChange={handleChange}
                  onKeyUp={handleFormatDateKeyUp}
                  label='Start Date'
                  placeholder='MM/DD/YYYY'
                  error={{ errorMessage: errors[START_DATE] }}
                />

                <TextField
                  id={END_DATE}
                  name={END_DATE}
                  value={formData[END_DATE]}
                  onChange={handleChange}
                  onKeyUp={handleFormatDateKeyUp}
                  label='End Date'
                  placeholder='MM/DD/YYYY'
                  error={{ errorMessage: errors[END_DATE] }}
                />
              </>
            ) : (
              <TextField
                id={VISIT_DATE}
                name={VISIT_DATE}
                value={formData[VISIT_DATE]}
                onChange={handleChange}
                onKeyUp={handleFormatDateKeyUp}
                label='Visit Date'
                placeholder='MM/DD/YYYY'
                error={{ errorMessage: errors[VISIT_DATE] }}
              />
            )}
          </div>

          <Checkbox
            label='This visit lasted multiple days'
            onChange={handleMultiDayClick}
            checked={isMultiDay}
            classes={{ root: styles.multiDayCheckbox }}
          />
        </div>

        <div>
          <VetClinicSearch
            zipcode={zipcode}
            onChange={handleVetSelectChange}
            onSelect={handleVetSelect}
            onClear={handleVetClear}
            label='Which vet did you visit?'
            placeholder='Search for a Vet...'
            defaultValue={formData[VET]?.vet_name || ''}
            error={{ errorMessage: errors[VET] }}
            classes={{ paper: styles.vetDropdown, textField: styles.vetField }}
            noneOption={{ primary: 'My provider is not on this list' }}
            stickyFooter={'My provider is not on this list'}
            isInline
          />

          {isCustomVet ? (
            <TextField
              id={VET_NAME}
              name={VET_NAME}
              value={formData[VET_NAME]}
              onChange={handleChange}
              label='Clinic or Pharmacy Name'
              classes={{ container: styles.vetName }}
              error={{ errorMessage: errors[VET_NAME] }}
            />
          ) : null}
        </div>

        <TextField
          id={AMOUNT_CLAIMED}
          name={AMOUNT_CLAIMED}
          value={formData[AMOUNT_CLAIMED]}
          onChange={e => handleChangeCurrencyDecimals(e, handleChange)}
          onBlur={e => handleChangeCurrencyAppendDecimals(e, handleChange)}
          label='Amount Claimed'
          error={{ errorMessage: errors[AMOUNT_CLAIMED] }}
          placeholder='0.00'
          startAdornment={{ startContent: '$' }}
          classes={{ startAdornment: styles.startAdornment }}
        />

        <div>
          <Body2 isBold>Is {pet.name} the only pet on this invoice?</Body2>

          <RadioGroup
            id='onlyPet'
            name={ONLY_PET}
            value={formData[ONLY_PET]}
            onChange={value => handleChange({ name: ONLY_PET, value })}
            classes={{ root: styles.radioGroup, inputs: styles.radioGroupInputs }}
            error={{ errorMessage: errors[ONLY_PET] }}
          >
            <Radio
              value={true}
              label={`Yes, just ${pet.name}`}
              classes={{
                root: classNames(styles.radio, {
                  [styles.radioError]: errors[ONLY_PET],
                }),
              }}
            />
            <Radio
              value={false}
              label='No, this invoice includes other pets'
              classes={{
                root: classNames(styles.radio, {
                  [styles.radioError]: errors[ONLY_PET],
                }),
              }}
            />
          </RadioGroup>

          {formData[ONLY_PET] === false ? (
            <LegalBody>
              Reminder: This claim is for {pet.name} only. Be sure to file a new
              claim for other Pumpkin pets.
            </LegalBody>
          ) : null}
        </div>

        <button
          className={ButtonStyles.secondary}
          type='submit'
          disabled={isProcessingClaimAttachment}
        >
          Next
        </button>
      </form>

      {isInvoiceModalOpen ? (
        <ClaimsInvoiceModal handleClose={toggleInvoiceModal} />
      ) : null}
    </ClaimFormAccordion>
  );
}

ClaimVisitDetails.propTypes = {
  canEdit: PropTypes.bool,
  isComplete: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isProcessingClaimAttachment: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onEditClick: PropTypes.func,
  onUploadFile: PropTypes.func,
  pet: PropTypes.object.isRequired,
  zipcode: PropTypes.string.isRequired,
};

export default ClaimVisitDetails;
