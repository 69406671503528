// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".claim-form_root_633b8d9f {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + " {\n    .claim-form_root_633b8d9f {\n        gap: 40px;\n    }\n}", "",{"version":3,"sources":["webpack://../../libs/claims/src/view/claim-form/claim-form.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI;QACI,SAAS;IACb;AACJ","sourcesContent":["@value desktop from '~@pumpkincare/shared/ui/breakpoints';\n\n.root {\n    display: flex;\n    flex-direction: column;\n    gap: 24px;\n}\n\n@media desktop {\n    .root {\n        gap: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["desktop"] + "",
	"root": "claim-form_root_633b8d9f"
};
export default ___CSS_LOADER_EXPORT___;
