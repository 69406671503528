import { dateIsBefore, formatISODate } from '@pumpkincare/shared';

import { getLatestRelevantPolicy, POLICY_STATUS } from './policy-config-utils';

const DEFAULT_RETURN = { dates: [], canContact: false };

/*
  This is highly dependent on reverseSortPolicies happening at the user-utils level
 */
export function getPolicyDates(policies) {
  if (!policies.length) return DEFAULT_RETURN;

  const latestPolicy = policies[0];
  const latestRelevantPolicy = getLatestRelevantPolicy(policies, {
    isSorted: true,
  });

  // pending on enrollment or after renewal
  if (latestPolicy.status === POLICY_STATUS.PENDING) {
    return { dates: [], canContact: false };
  }

  const isModelLaw =
    latestPolicy.policy_accident_coverage_start_date !==
    latestPolicy.policy_illness_coverage_start_date;
  const now = Date.now();

  if (
    [POLICY_STATUS.ACTIVE, POLICY_STATUS.LAPSED].some(
      status => status === latestRelevantPolicy.status
    )
  ) {
    // isFirstYear will check that the oldest policy is not active - this accounts for renewal policies in the 70 day window before renewal
    const isFirstYear = [POLICY_STATUS.ACTIVE, POLICY_STATUS.LAPSED].some(
      status => status === policies[policies.length - 1].status
    );
    const isDeclined = latestPolicy.status === POLICY_STATUS.DECLINED;
    const isRenewal = latestPolicy.status === POLICY_STATUS.RENEW_PENDING;

    // check against first year before checking waiting periods to prevent expensive date calculations
    const isBeforeAccidentStart =
      isFirstYear &&
      dateIsBefore(now, latestRelevantPolicy.policy_accident_coverage_start_date);
    const isBeforeIllnessStart =
      isFirstYear &&
      (isModelLaw
        ? dateIsBefore(now, latestRelevantPolicy.policy_illness_coverage_start_date)
        : isBeforeAccidentStart);

    /*
     a lot of logic here relies on accident waiting periods being less than illness waiting periods
     this logic will need to be updated if that ever changes
     */

    // initialize dates return - if in waiting period, add effective date copy
    const dates =
      isBeforeAccidentStart || isBeforeIllnessStart
        ? [
            {
              title: 'Insurance Effective Date',
              date: formatISODate(latestRelevantPolicy.policy_effective_date, {
                format: 'MMM D, YYYY',
                inputTimezone: 'local',
              }),
              subtitle: !isModelLaw ? 'Waiting period starts' : '',
            },
          ]
        : [];

    // add accident / insurance (dependent on model law) coverage date, and waiting period if applicable
    if (isBeforeAccidentStart && !isModelLaw) {
      dates.push({
        title: `Waiting Period`,
        date: `${formatISODate(latestRelevantPolicy.policy_effective_date, {
          format: 'MMM D',
          inputTimezone: 'local',
        })} - ${formatISODate(
          latestRelevantPolicy.policy_accident_coverage_start_date,
          { format: 'MMM D, YYYY', inputTimezone: 'local' }
        )}`,
      });
    }

    dates.push({
      title: `${isModelLaw ? 'Accident' : 'Insurance'} Coverage Start Date`,
      date: formatISODate(latestRelevantPolicy.policy_accident_coverage_start_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      }),
      subtitle: `${isModelLaw ? 'Accident' : 'Insurance'} coverage ${
        isBeforeAccidentStart ? 'kicks' : 'kicked'
      } in`,
    });

    // add illness coverage date, and waiting period if applicable if model law
    if (isModelLaw) {
      if (isBeforeIllnessStart) {
        dates.push({
          title: 'Illness Waiting Period',
          date: `${formatISODate(latestRelevantPolicy.policy_effective_date, {
            format: 'MMM D',
            inputTimezone: 'local',
          })} - ${formatISODate(
            latestRelevantPolicy.policy_illness_coverage_start_date,
            { format: 'MMM D, YYYY', inputTimezone: 'local' }
          )}`,
          subtitle: 'Waiting period for illness coverage',
        });
      }
      dates.push({
        title: 'Illness Coverage Start Date',
        date: formatISODate(
          latestRelevantPolicy.policy_illness_coverage_start_date,
          { format: 'MMM D, YYYY', inputTimezone: 'local' }
        ),
        subtitle: `Illness coverage ${isBeforeIllnessStart ? 'kicks' : 'kicked'} in`,
      });
    }

    // if user is not in their first year, add the current policy's effective date information
    if (!isFirstYear || isRenewal) {
      dates.push({
        title: `Current Policy Effective Date`,
        date: formatISODate(latestRelevantPolicy.policy_effective_date, {
          format: 'MMM D, YYYY',
          inputTimezone: 'local',
        }),
        subtitle: 'Policy period began',
      });
    }

    // if user is not in a waiting period, add the relevant policy end date - whether that's via renewal or cancellation
    if (!isBeforeAccidentStart && !isBeforeIllnessStart) {
      const endDate = formatISODate(latestRelevantPolicy.policy_end_date, {
        format: 'MMM D, YYYY',
        inputTimezone: 'local',
      });

      const renewal = {
        title: `${!isFirstYear ? 'Next ' : ''}Policy Renewal Date`,
        date: endDate,
        subtitle: `Policy auto renews${isModelLaw ? ' for accident & illness' : ''}`,
      };
      const declined = {
        title: 'Insurance Cancellation Date',
        date: endDate,
        subtitle: 'Coverage ends',
      };

      dates.push(isDeclined ? declined : renewal);
    }

    return {
      dates,
      canContact: !isDeclined && !(isBeforeAccidentStart || isBeforeIllnessStart),
    };
  }

  if (
    [POLICY_STATUS.CANCELLED, POLICY_STATUS.EXPIRED].some(
      status => status === latestRelevantPolicy.status
    ) ||
    [POLICY_STATUS.VOIDED, POLICY_STATUS.CANCEL_PENDING].some(
      status =>
        status === latestPolicy.status || status === latestRelevantPolicy.status
    )
  ) {
    return {
      dates: [
        {
          title: 'Coverage End Date',
          date: formatISODate(latestRelevantPolicy.policy_end_date, {
            format: 'MMM D, YYYY',
            inputTimezone: 'local',
          }),
          subtitle: 'Coverage ends',
        },
      ],
      canContact: false,
    };
  }

  return DEFAULT_RETURN;
}
