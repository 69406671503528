import { useState } from 'react';

export default function useTargetState(initialValue) {
  const [value, setValue] = useState(initialValue);

  const setTargetsValue = e => {
    const newValue = typeof e === 'string' ? e : e.target.value;
    setValue(newValue);
  };

  return [value, setTargetsValue];
}
