import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Body2, ButtonStyles, Modal, Typography } from '@pumpkincare/shared/ui';

import styles from './claims-visit-invoice-modal.css';

import invoiceExample from '../../invoice_example.svg';

function ClaimsInvoiceModal({ handleClose }) {
  return (
    <Modal
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
      classes={{ content: styles.modalContainer }}
    >
      <h3 id='modal-title' className={styles.modalTitle}>
        How do I make sure my invoice is clear & readable?
      </h3>
      <div className={styles.primaryContent}>
        <div>
          <Body2 id='modal-description' className={styles.title}>
            Providing an invoice that we can easily read allows us to process your
            claim faster.
          </Body2>
          <ol className={classNames(Typography.body2, styles.tipList)}>
            <li>Invoices should be itemized, with all line items visible.</li>
            <li>
              Make sure there is nothing on top of the invoice (e.g., a stapled
              receipt).
            </li>
            <li>
              If taking a photo, keep the entire page within your camera’s frame. If
              your invoice is multiple pages, take a separate photo of each page.
            </li>
          </ol>
        </div>
        <img src={invoiceExample} alt='' className={styles.exampleImages} />
      </div>

      <button
        className={classNames(ButtonStyles.secondary, styles.closeButton)}
        onClick={handleClose}
        type='button'
      >
        Close
      </button>
    </Modal>
  );
}

ClaimsInvoiceModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ClaimsInvoiceModal;
