export function getStripeError(err) {
  const errorCode = err.response?.data?.message || 'generic_error';

  return {
    insufficient_funds: 'This card has insufficient funds.',
    incorrect_address: 'This billing address is incorrect.',
    incorrect_cvc: 'Incorrect cvc code.',
    incorrect_number: 'Incorrect card number.',
    incorrect_zip: 'Incorrect Zip Code.',
    invalid_card_type: 'Invalid card type.',
    prepaid_card: 'Invalid card type.',
    generic_error:
      'There was an error processing your payment. Please review your information and try an alternative card.',
    authentication_required:
      'The card was declined because the transaction requires authentication, such as 3D Secure .',
    approve_with_id: 'Unable to authorize payment.',
    call_issuer:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    card_not_supported: 'The card does not support this type of purchase.',
    card_velocity_exceeded:
      'The customer has exceeded the balance or credit limit available on their card.',
    currency_not_supported: 'The card does not accept the specified currency.',
    do_not_honor:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    do_not_try_again:
      'The card has been declined for an unknown reason. Please contact your bank for more information.',
    duplicate_transaction:
      'A transaction with identical amount and credit card details was recently executed.',
    expired_card: 'The card has expired.',
    generic_decline:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    incorrect_pin:
      'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
    invalid_account:
      'The card, or the account to which the card is linked, is invalid.',
    invalid_amount:
      'The payment amount is invalid, or exceeds the amount that is allowed.',
    invalid_cvc: 'The CVC number is incorrect.',
    invalid_expiry_year: 'The expiration year invalid.',
    invalid_number: 'The card number is incorrect.',
    invalid_pin:
      'The PIN entered is incorrect. This decline code only applies to payments made with a card reader.',
    issuer_not_available:
      'The card issuer could not be reached, so the payment could not be authorized.',
    new_account_information_available:
      'The card, or the account to which the card is linked, is invalid.',
    no_action_taken:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    not_permitted: 'The payment is not permitted.',
    offline_pin_required: 'The card has been declined as it requires a PIN.',
    online_or_offline_pin_required: 'The card was declined as it requires a PIN.',
    pickup_card:
      'The card cannot be used to make this payment (it is possible it has been reported lost or stolen).',
    pin_try_exceeded: 'The limit of attempts to enter the PIN has been exceeded.',
    processing_error: 'An error occurred while processing the card.',
    reenter_transaction:
      'The payment could not be processed by the issuer for an unknown reason.',
    restricted_card:
      'The customer cannot use this card to make payment (it is possible that it has been declared lost or stolen).',
    revocation_of_all_authorizations:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    revocation_of_authorization:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    security_violation:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    service_not_allowed: 'The card was declined for an unknown reason.',
    stop_payment_order:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    testmode_decline: 'A Stripe test card number was used.',
    transaction_not_allowed: 'The card was declined for an unknown reason.',
    try_again_later:
      'The card has been declined for an unknown reason. Please contact the card issuer for more information.',
    withdrawal_count_limit_exceeded:
      'The customer has exceeded the balance or credit limit available on their card.',
  }[errorCode];
}
