// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../../../shared/src/ui/breakpoints.module.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".claims-terms-modal_legalText_d7b236fa {\n  padding-bottom: 24px;\n}\n\n@media " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["upSm"] + " {\n  .claims-terms-modal_legalText_d7b236fa {\n    padding-bottom: 24px;\n  }\n\n  .claims-terms-modal_modalContainer_29d969a1 {\n    width: 960px;\n  }\n}\n", "",{"version":3,"sources":["webpack://../../libs/claims/src/view/claim-form/claim-records-submit/claims-terms-modal/claims-terms-modal.css"],"names":[],"mappings":"AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE;IACE,oBAAoB;EACtB;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":["@value upSm from '~@pumpkincare/shared/ui/breakpoints';\n\n.legalText {\n  padding-bottom: 24px;\n}\n\n@media upSm {\n  .legalText {\n    padding-bottom: 24px;\n  }\n\n  .modalContainer {\n    width: 960px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upSm": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["upSm"] + "",
	"legalText": "claims-terms-modal_legalText_d7b236fa",
	"modalContainer": "claims-terms-modal_modalContainer_29d969a1"
};
export default ___CSS_LOADER_EXPORT___;
