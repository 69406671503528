import {
  currentISODate,
  formatISODate,
  isValidCurrencyString,
  validateDateMMDDYYYNotBefore,
} from '@pumpkincare/shared';

import {
  AMOUNT_CLAIMED,
  END_DATE,
  FILE_LIST,
  ONLY_PET,
  START_DATE,
  VET,
  VET_NAME,
  VISIT_DATE,
} from './claim-visit-details-shared';

export function validateClaimVisitDetails({ data, isMultiDay, isCustomVet }) {
  const invalid = {};
  if (!data[FILE_LIST].length)
    invalid[FILE_LIST] = 'Upload an invoice from your vet visit';

  const current = formatISODate(currentISODate(), {
    inputTimezone: 'local',
    format: 'MM/DD/YYYY',
  });

  if (
    !isMultiDay &&
    (!data[VISIT_DATE] || !validateDateMMDDYYYNotBefore(current, data[VISIT_DATE]))
  )
    invalid[VISIT_DATE] = 'Add a valid visit date';

  if (
    isMultiDay &&
    (!data[START_DATE] || !validateDateMMDDYYYNotBefore(current, data[START_DATE]))
  )
    invalid[START_DATE] = 'Add a valid start date';
  if (
    isMultiDay &&
    (!data[END_DATE] || !validateDateMMDDYYYNotBefore(current, data[END_DATE]))
  )
    invalid[END_DATE] = 'Add a valid end date';
  if (
    isMultiDay &&
    data[END_DATE] &&
    data[START_DATE] &&
    validateDateMMDDYYYNotBefore(data[START_DATE], data[END_DATE])
  )
    invalid[END_DATE] = 'Add an end date after your start date';

  if (!data[AMOUNT_CLAIMED])
    invalid[AMOUNT_CLAIMED] = "Enter the amount you're claiming";
  if (data[AMOUNT_CLAIMED] && !isValidCurrencyString(data[AMOUNT_CLAIMED]))
    invalid[AMOUNT_CLAIMED] = 'Enter a valid amount';

  if (!isCustomVet && !data[VET].id)
    invalid[VET] = 'Find and select the vet you visited';
  if (isCustomVet && !data[VET_NAME])
    invalid[VET_NAME] = 'Enter clinic or pharmacy name';

  if (typeof data[ONLY_PET] !== 'boolean') invalid[ONLY_PET] = 'Select an option';

  return invalid;
}
