import { fetchAuthSession } from 'aws-amplify/auth';
import axios from 'axios';

import { POLICY_BASE_API } from '@pumpkincare/config';
import { buildAuthHeaders, responseDataBody } from '@pumpkincare/shared';

import { transformClaimPayloadWithHeaders } from './claim-utils';

export function downloadClaimDocument(type, claimId) {
  let url;

  if (type === 'Insurance') {
    url = `${POLICY_BASE_API}/users/protect-claim-eob/${claimId}`;
  } else {
    url = `${POLICY_BASE_API}/users/prevent-claim-summary/${claimId}`;
  }

  return fetchAuthSession().then(authSession =>
    axios
      .get(url, buildAuthHeaders(authSession))
      .then(response => response.data.body.url)
  );
}

export function submitClaim(claim) {
  return fetchAuthSession().then(authSession => {
    return axios.post(
      `${POLICY_BASE_API}/users/claims`,
      claim,
      buildAuthHeaders(authSession)
    );
  });
}

export const ClaimsService = {
  downloadClaimDocument,
  submitClaim,
};

export function getClaims(filter, range) {
  const rangeParam =
    range?.length > 0 ? `range=${encodeURIComponent(JSON.stringify(range))}` : '';

  const filterParam =
    Object.keys(filter)?.length > 0
      ? `&filter=${encodeURIComponent(JSON.stringify(filter))}`
      : '';

  return fetchAuthSession().then(authSession => {
    return axios
      .get(
        `${POLICY_BASE_API}/claims?${rangeParam}${filterParam}`,
        buildAuthHeaders(authSession)
      )
      .then(transformClaimPayloadWithHeaders);
  });
}

export function postClaim(claim) {
  return fetchAuthSession().then(authSession => {
    return axios
      .post(`${POLICY_BASE_API}/claims`, claim, buildAuthHeaders(authSession))
      .then(responseDataBody);
  });
}

export function postClaimV2(claim) {
  return fetchAuthSession().then(authSession => {
    return axios
      .post(`${POLICY_BASE_API}/claims/v2`, claim, buildAuthHeaders(authSession))
      .then(responseDataBody);
  });
}
