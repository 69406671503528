import { useQuery } from 'react-query';

import { TEN_MINUTES } from '@pumpkincare/shared';

import { getPetIncidentHistory, getSubDiagnoses } from './user-service';

export const DIAGNOSES_QUERY = 'diagnoses';
export const PET_INCIDENTS_QUERY = 'pet-incidents';

export function useSubDiagnoses({ onFetch, ...restOptions } = {}) {
  return useQuery(
    [DIAGNOSES_QUERY],
    () => getSubDiagnoses().then(data => onFetch?.(data) || data),
    {
      placeholderData: [],
      staleTime: TEN_MINUTES,
      ...restOptions,
    }
  );
}

export function usePetIncidentsQuery(petId, { onFetch, ...restOptions } = {}) {
  return useQuery(
    [PET_INCIDENTS_QUERY, petId],
    () =>
      getPetIncidentHistory(petId).then(
        ({ incident_history }) => onFetch?.(incident_history) || incident_history
      ),
    {
      placeholderData: [],
      staleTime: TEN_MINUTES,
      ...restOptions,
    }
  );
}
