import SvgIcon, { SvgIconPropTypes } from './svg-icon';

function Calendar(props) {
  return (
    <SvgIcon fill='none' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15 3V2C15 1.44772 15.4477 1 16 1C16.5523 1 17 1.44772 17 2V3H19C20.6569 3 22 4.34315 22 6V10V20C22 21.6569 20.6569 23 19 23H5C3.34315 23 2 21.6569 2 20V10V6C2 4.34315 3.34315 3 5 3H7V2C7 1.44772 7.44772 1 8 1C8.55228 1 9 1.44772 9 2V3H15ZM4 11V20C4 20.5523 4.44772 21 5 21H19C19.5523 21 20 20.5523 20 20V11H4ZM20 9H4V6C4 5.44772 4.44772 5 5 5H7V6C7 6.55228 7.44772 7 8 7C8.55228 7 9 6.55228 9 6V5H15V6C15 6.55228 15.4477 7 16 7C16.5523 7 17 6.55228 17 6V5H19C19.5523 5 20 5.44772 20 6V9Z'
        fill='#9B9FA8'
      />
    </SvgIcon>
  );
}

Calendar.propTypes = {
  ...SvgIconPropTypes,
};

export default Calendar;
