import { useQuery } from 'react-query';

import { ONE_HOUR } from '@pumpkincare/shared';

import { getInvoices, getNextCharges } from './user-service';

const INVOICES_QUERY = 'invoices';
const NEXT_CHARGES_QUERY = 'nextCharges';

export function useInvoices({ onFetch, ...options } = {}) {
  return useQuery(
    [INVOICES_QUERY],
    () => getInvoices().then(data => onFetch?.(data) || data),
    {
      placeholderData: [],
      staleTime: ONE_HOUR,
      ...options,
    }
  );
}

export function useNextCharges({ onFetch, ...options } = {}) {
  return useQuery(
    [NEXT_CHARGES_QUERY],
    () => getNextCharges().then(data => onFetch?.(data) || data),
    {
      placeholderData: [],
      staleTime: ONE_HOUR,
      ...options,
    }
  );
}
