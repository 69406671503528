// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".coverage-documents_root_66c03eb4 {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.coverage-documents_toggle_2f1d1ee2 {\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.coverage-documents_open_a36f6b7b {\n    transition: 0.2s;\n    transform: rotate(270deg);\n}\n\n.coverage-documents_closed_3007299d {\n    transition: 0.2s;\n    transform: rotate(90deg);\n}", "",{"version":3,"sources":["webpack://../../libs/user/src/view/coverage-documents/coverage-documents.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;IAChB,wBAAwB;AAC5B","sourcesContent":[".root {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n\n.toggle {\n    margin: 0 auto;\n    display: flex;\n    align-items: center;\n    gap: 8px;\n}\n\n.open {\n    transition: 0.2s;\n    transform: rotate(270deg);\n}\n\n.closed {\n    transition: 0.2s;\n    transform: rotate(90deg);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "coverage-documents_root_66c03eb4",
	"toggle": "coverage-documents_toggle_2f1d1ee2",
	"open": "coverage-documents_open_a36f6b7b",
	"closed": "coverage-documents_closed_3007299d"
};
export default ___CSS_LOADER_EXPORT___;
