export function responseDataBody(response) {
  return response?.data?.body || response?.data;
}

export function buildAuthHeaders(authSession) {
  return {
    headers: {
      Authorization: `Bearer ${authSession.tokens.idToken.toString()}`,
    },

    response: true,
  };
}
