import {
  currentISODate,
  formatISODate,
  validateDateMMDDYYYNotBefore,
} from '@pumpkincare/shared';

import {
  ACCIDENT,
  CONDITION_DATE,
  CONDITION_TYPE,
  GENERIC,
  ILLNESS,
  INCIDENTS,
  VISIT_REASON,
  WELLNESS,
} from './claim-conditions-services-shared';

export function validateClaimConditionsServices({ data }) {
  const invalid = {};

  if (
    !(
      [ACCIDENT, ILLNESS].some(box => !!data[box].length) ||
      data[WELLNESS] ||
      Object.values(data[INCIDENTS]).some(value => value)
    )
  ) {
    invalid[GENERIC] = 'Select one or more';
  }

  const current = formatISODate(currentISODate(), {
    inputTimezone: 'local',
    format: 'MM/DD/YYYY',
  });

  [ACCIDENT, ILLNESS].forEach(type => {
    data[type].forEach((condition, idx) => {
      invalid[type] = invalid[type] || [];
      invalid[type].push({});

      if (!condition[CONDITION_TYPE])
        invalid[type][idx][CONDITION_TYPE] = 'Select a condition';

      if (
        !condition[CONDITION_DATE] ||
        !validateDateMMDDYYYNotBefore(current, condition[CONDITION_DATE])
      )
        invalid[type][idx][CONDITION_DATE] = 'Enter a valid condition start date';
    });
  });

  if (!data[VISIT_REASON])
    invalid[VISIT_REASON] = 'Please describe why you visited the vet';

  return invalid;
}

/*
  invalid can be structured like:
  { [GENERIC]: 'someError', [VISIT_REASON]: 'someOtherError' } - easy to validate
  { [ACCIDENT]: [{ }, { [CONDITION_DATE]: '' }, { [CONDITION_TYPE]: 'Select an option' }] } - harder
 */
export function isInvalidClaimConditionsServices(invalid) {
  if (invalid[GENERIC] || invalid[VISIT_REASON]) return true;

  const hasNestedError = [invalid[ACCIDENT], invalid[ILLNESS]]
    .filter(item => !!item)
    .some(conditions => {
      return conditions.some(condition => {
        return Object.keys(condition).some(key => !!condition[key]);
      });
    });

  return hasNestedError;
}
