import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useBooleanInput } from '@pumpkincare/shared';
import {
  Body2,
  ButtonStyles,
  ChevronRight,
  DocumentButton,
} from '@pumpkincare/shared/ui';

import styles from './coverage-documents.css';

function CoverageDocuments({ classes, title, documents, handleDocumentClick }) {
  const [isAllDocuments, toggleAllDocuments] = useBooleanInput(false);

  return (
    <div className={classNames(styles.root, classes.root)}>
      <Body2 isBold>{title}</Body2>

      {(isAllDocuments ? documents : [documents[0]]).map(document =>
        document.processingText ? (
          <Body2 key={document.id}>{document.processingText}</Body2>
        ) : (
          <DocumentButton
            key={document.id}
            onClick={() => handleDocumentClick(document)}
            title={document.name}
            subtitle={document.subtitle}
            icon={'download'}
          />
        )
      )}

      {documents.length > 1 ? (
        <button
          className={classNames(styles.toggle, ButtonStyles.tertiary)}
          onClick={toggleAllDocuments}
        >
          {isAllDocuments ? 'Show Most Recent Document only' : 'View All Documents'}
          <ChevronRight
            width={8}
            height={14}
            className={isAllDocuments ? styles.open : styles.closed}
          />
        </button>
      ) : null}
    </div>
  );
}

CoverageDocuments.defaultProps = {
  classes: {},
  title: 'Full Coverage Details',
};

CoverageDocuments.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),

  title: PropTypes.string,
  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      processingText: PropTypes.string,
    })
  ).isRequired,
  handleDocumentClick: PropTypes.func.isRequired,
};

export default CoverageDocuments;
