import { useMutation, useQueryClient } from 'react-query';

import { PAYMENT_QUERY } from './payment-query';
import { deleteUserAch, putUserAch, putUserPaymentMethod } from './user-service';

export function useMutateUserPaymentMethod() {
  const queryClient = useQueryClient();

  return useMutation(({ stripeToken }) => putUserPaymentMethod(stripeToken), {
    onSuccess: async (data, variables) => {
      await queryClient.cancelQueries([PAYMENT_QUERY]);

      const paymentData = queryClient.getQueryData([PAYMENT_QUERY]);
      const { brand, expMonth, expYear, last4 } = variables;

      queryClient.setQueryData([PAYMENT_QUERY], {
        ...paymentData,

        paymentMethod: { brand, expMonth, expYear, last4 },
      });

      return { prevData: paymentData };
    },
  });
}

export function useMutateUserAch() {
  const queryClient = useQueryClient();

  return useMutation(({ body }) => putUserAch(body), {
    onSettled: () => queryClient.invalidateQueries([PAYMENT_QUERY]),

    onMutate: async variables => {
      await queryClient.cancelQueries([PAYMENT_QUERY]);

      const paymentData = queryClient.getQueryData([PAYMENT_QUERY]);
      const { body } = variables;

      queryClient.setQueryData([PAYMENT_QUERY], {
        ...paymentData,

        fundingSource: {
          accountNumber: body.account_number,
          accountType: body.account_type,
          nameOnAccount: body.name_on_account,
          routingNumber: body.routing_number,
        },
      });

      return { prevData: paymentData };
    },
  });
}

export function useDeleteUserAch() {
  const queryClient = useQueryClient();

  return useMutation(deleteUserAch, {
    onSettled: () => queryClient.invalidateQueries([PAYMENT_QUERY]),

    onMutate: async () => {
      await queryClient.cancelQueries([PAYMENT_QUERY]);

      const paymentData = queryClient.getQueryData([PAYMENT_QUERY]);

      queryClient.setQueryData([PAYMENT_QUERY], {
        ...paymentData,

        fundingSource: {
          accountNumber: '',
          accountType: '',
          nameOnAccount: '',
          routingNumber: '',
        },
      });

      return { prevData: paymentData };
    },
  });
}
