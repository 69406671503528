import { createContext, useContext, useState } from 'react';

const QUOTES_PROVIDER_ERROR = new Error(
  'The hook must be used within a QuotesProvider'
);

const QuotesActiveId = createContext();
const QuotesIsAddMode = createContext();
const QuotesPetOrder = createContext();

/*
  QuotesProvider is a top level state for the quote flow to control state across plan-selection, quote pages, and checkout
  This is specifically FE state and not server state
  @param {string} activeId - when in multipet, controls which pet is showing for quote flow and plan selection
  @param {object} petOrder - for controlling data sent to analytics (e.g. PET1BREED v PET2BREED in analytics)
  @param {string} isAddMode - for intro page, show multipet copy and CTAs if true
  The initial values should only really be used for tests
 */
export default function QuotesProvider({
  children,
  initialActiveId = '',
  initialPetOrder = {},
  initialIsAddMode = false,
}) {
  return (
    <QuotesActiveId.Provider value={useState(initialActiveId)}>
      <QuotesIsAddMode.Provider value={useState(initialIsAddMode)}>
        <QuotesPetOrder.Provider value={useState(initialPetOrder)}>
          {children}
        </QuotesPetOrder.Provider>
      </QuotesIsAddMode.Provider>
    </QuotesActiveId.Provider>
  );
}

export function useQuotesActiveId(defaultValue) {
  const context = useContext(QuotesActiveId);

  if (context === undefined) {
    throw QUOTES_PROVIDER_ERROR;
  }

  const [activeId, setActiveId] = context;
  return { activeId: activeId || defaultValue, setActiveId };
}

export function useQuotesPetOrder() {
  const context = useContext(QuotesPetOrder);

  if (context === undefined) {
    throw QUOTES_PROVIDER_ERROR;
  }

  const [petOrder, handlePetOrder] = context;

  function setNewPetInOrder(newId) {
    handlePetOrder(state => {
      const newIndex = Object.keys(state).length + 1;

      return {
        ...state,
        [newId]: newIndex,
      };
    });
  }

  function setAllPetsOrders(pets) {
    handlePetOrder(
      pets.reduce((acc, pet, index) => {
        acc[pet.id] = index + 1;
        return acc;
      }, {})
    );
  }

  return { petOrder, setNewPetInOrder, setAllPetsOrders };
}

export function useQuotesIsAddMode() {
  const context = useContext(QuotesIsAddMode);

  if (context === undefined) {
    throw QUOTES_PROVIDER_ERROR;
  }

  const [isAddMode, handleIsAddModeChange] = context;

  function setIsAddMode(value) {
    if (typeof value === 'boolean') {
      handleIsAddModeChange(value);
    } else {
      throw new Error('Value must be a boolean');
    }
  }

  return { isAddMode, setIsAddMode };
}
