import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { replace } from 'connected-react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import {
  ARF_MEOW_PHONE_NUMBER,
  CONTACT_EMAIL,
  FORMATTED_PHONE_NUMBER,
} from '../../constants/contacts';
import { Body1, Circle, SocialMedia } from '../../ui';

import styles from './maintenance-page.css';

function MaintenancePage() {
  const dispatch = useDispatch();
  const { outageFlag } = useFlags();
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (outageFlag && !Object.keys(outageFlag).length) {
      dispatch(replace('/'));
    }
  }, [dispatch, outageFlag]);

  return (
    <div className={styles.errorPage}>
      <div className={styles.circles}>
        <Circle size={64} classes={{ root: styles.circleYellow }} fill='plutoGold' />
        <Circle
          size={96}
          classes={{ root: styles.circleBlue }}
          fill='bluesCluesBlue'
        />
        <Circle size={152} classes={{ root: styles.circleRed }} fill='cliffordRed' />
      </div>

      <div className={styles.errorPageContent}>
        <h1 className={styles.header}>Ruff day in cyberspace...</h1>

        <Body1 className={styles.errorText}>
          Our IT team is chasing down the issue so we can get back up and running
          ASAP. If you already shared your contact info, we’ll send you an email with
          a link to access your saved quote later. If you didn’t share your contact
          info, no worries – just visit pumpkin.care/quote later to start a new
          quote.
        </Body1>

        <h4 className={styles.h4}>Have questions? We’re a paw away.</h4>

        <Body1>
          {`${ARF_MEOW_PHONE_NUMBER} (${FORMATTED_PHONE_NUMBER}) | `}
          {CONTACT_EMAIL}
        </Body1>

        <SocialMedia
          containerProps={{ className: styles.socialLinks }}
          anchorProps={{ className: styles.socialLinksItem }}
          imageProps={{ className: styles.socialLinksImage }}
        />
      </div>
    </div>
  );
}

export default MaintenancePage;
