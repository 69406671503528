import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  Body1,
  ButtonStyles,
  ChevronRight,
  Heart,
  LegalBody,
  RouteLink as Link,
} from '@pumpkincare/shared/ui';

import { getPolicyConfig } from '../../policy-config-utils';
import { getWellnessConfig } from '../../wellness-config-utils';

import styles from './pet-card.css';

function PetCard({ pet, toPet, toClaim, petPhoto, classes = {} }) {
  const {
    renewal: insRenewal,
    declined: insDeclined,
    cancelled: insCancelled,
    waiting: insWaiting,
    lapsed: insLapsed,
  } = getPolicyConfig(pet.policies);

  const {
    renewal: wellnessRenewal,
    declined: wellnessDeclined,
    cancelled: wellnessCancelled,
    lapsed: wellnessLapsed,
  } = getWellnessConfig(pet.wellness);

  function CardCopy() {
    let copy = '';
    let hasHeartIcon = false;

    if (
      insRenewal?.isRenewal ||
      (insRenewal?.isRenewal && wellnessRenewal?.isRenewal)
    ) {
      copy = (
        <>
          Upcoming renewal on <b>{insRenewal.renewalDate}</b>
        </>
      );
    } else if (wellnessRenewal?.isRenewal) {
      copy = (
        <>
          Wellness renewal on <b>{wellnessRenewal.renewalDate}</b>
        </>
      );
    }

    if (insDeclined?.isDeclined || wellnessDeclined?.isDeclined) {
      copy = (
        <>
          Plan cancels on <b>{insDeclined?.endDate || wellnessDeclined?.endDate}</b>
        </>
      );
    }

    // When a pet is deceased it must already be cancelled
    if (wellnessCancelled?.isDeceased || insCancelled?.isDeceased) {
      copy = 'In loving memory';
      hasHeartIcon = true;
    } else if (
      // A pet can have insurance and/or wellness cancelled
      // When they have both, we check if both are cancelled
      // When they have only insurance, wellness object is empty, and we check if insurance is cancelled
      // When they have only wellness, insurance object is empty, and we check if wellness is cancelled
      (wellnessCancelled?.isCancelled && insCancelled?.isCancelled) ||
      (!Object.keys(wellnessCancelled || {}).length && insCancelled.isCancelled) ||
      (!Object.keys(insCancelled || {}).length && wellnessCancelled.isCancelled)
    ) {
      copy = 'Cancelled';
    }

    //This should display if either insurance or wellness is lapsed
    if (insLapsed?.isLapsed || wellnessLapsed?.isLapsed) {
      copy = 'Billing lapsed';
    }

    if (insDeclined?.isDeclined || wellnessDeclined?.isDeclined) {
      copy = `Plan cancels on ${insDeclined?.endDate || wellnessDeclined?.endDate}`;
    }

    //we should display their insurance waiting anyway considering there is no wellness waiting period
    if (insWaiting.isWaiting && copy === '') {
      copy = insWaiting.isModelLaw ? (
        <>
          Illness waiting period until <b>{insWaiting.startDate}</b>
        </>
      ) : (
        <>
          Waiting period until <b>{insWaiting.startDate}</b>
        </>
      );
    }

    return (
      <div className={styles.cardCopy}>
        <LegalBody>
          {copy}
          {hasHeartIcon ? (
            <Heart className={styles.heart} width={21} height={16} />
          ) : null}
        </LegalBody>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <Link to={toPet} className={styles.top}>
        <div className={classNames(classes.imageWrapper, styles.imageWrapper)}>
          <img
            src={petPhoto}
            alt=''
            className={classNames(classes.petPhoto, styles.petPhoto)}
          />
        </div>
      </Link>

      <div className={styles.petCardWrapper}>
        <div className={styles.content}>
          <Link to={toPet} className={styles.top}>
            <div className={classNames(styles.name)}>
              <Body1
                isBold
                style={{
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                }}
              >
                {pet.name}
              </Body1>
              <ChevronRight width={8} height={14} />
            </div>

            <CardCopy />
          </Link>

          {toClaim ? (
            <div className={styles.claim}>
              <Link
                to={toClaim}
                className={classNames(styles.button, ButtonStyles.tertiary)}
              >
                File a claim
              </Link>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

PetCard.propTypes = {
  /** pet should just be the BE response associated with a pet */
  pet: PropTypes.object.isRequired,
  /** link for viewing more pet details */
  toPet: PropTypes.string.isRequired,
  /** link for filing a claim for a pet */
  toClaim: PropTypes.string,
  /** petPhoto should be the URL of the pet's photo */
  petPhoto: PropTypes.string,
  /** classes to pass to the component */
  classes: PropTypes.shape({
    imageWrapper: PropTypes.string,
    petPhoto: PropTypes.string,
  }),
};

export default PetCard;
