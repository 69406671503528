import { useMutation, useQueryClient } from 'react-query';

import { USER_ADDRESSES_QUERY } from './user-address-query';
import { USER_ALL_PET_PHOTOS_QUERY } from './user-all-pet-photos-query';
import { USER_PET_PHOTO_QUERY } from './user-pet-photo-query';
import { USER_PROFILE_QUERY } from './user-profile-query';
import {
  deleteUserPetPhotoById,
  getSwifterToken,
  obtainSignedUrlForFiles,
  postPetPhoto,
  postSignedUrlForPetPhoto,
  putUserBillingAddress,
  updateUserPaperless,
  uploadAttachments,
} from './user-service';

export function useMutateUserPaperless() {
  const queryClient = useQueryClient();

  return useMutation(
    ({ is_paperless, terms_version }) =>
      updateUserPaperless({ is_paperless, terms_version }),
    {
      onSettled: () => queryClient.invalidateQueries([USER_PROFILE_QUERY]),

      onMutate: async variables => {
        await queryClient.cancelQueries([USER_PROFILE_QUERY]);

        const profileData = queryClient.getQueryData([USER_PROFILE_QUERY]);
        const { is_paperless, terms_version } = variables;

        queryClient.setQueryData([USER_PROFILE_QUERY], {
          ...profileData,

          is_paperless,
          terms_version,
        });

        return { prevData: profileData };
      },
    }
  );
}

export function useMutateUserBillingAddress() {
  const queryClient = useQueryClient();

  return useMutation(({ address }) => putUserBillingAddress(address), {
    onSuccess: async (data, variables) => {
      await queryClient.cancelQueries([USER_ADDRESSES_QUERY]);

      const addressData = queryClient.getQueryData([USER_ADDRESSES_QUERY]);

      queryClient.setQueryData([USER_ADDRESSES_QUERY], {
        ...addressData,

        billing: {
          ...addressData.billing,
          street_1: variables.address.street_1,
          street_2: variables.address.street_2,
          city: variables.address.city,
          state_province: variables.address.state,
          zipcode: variables.address.zipcode,
          country: variables.address.country,
        },
      });

      return { prevData: addressData };
    },
  });
}

export function useMutateUserPostSignedUrlPetPhoto() {
  return useMutation(({ fileName }) => postSignedUrlForPetPhoto(fileName), {
    onSuccess: async (data, variables) => {
      const signedUrlParams = data[variables.fileName];
      const signedUrl = signedUrlParams.url;

      return { signedUrl };
    },
  });
}

export function useMutatePostSignedUrl({ hookOptions = {} } = {}) {
  return useMutation(
    ({ files, type }) => obtainSignedUrlForFiles(files, type),
    hookOptions
  );
}

export function useMutateUploadToS3() {
  return useMutation(
    ({ file, signedUrls }) => uploadAttachments([file], signedUrls),
    {
      onSuccess: async (data, variables) => {
        const { file } = variables;

        return file;
      },
    }
  );
}

export function useMutatePostPetPhoto(petId) {
  const queryClient = useQueryClient();
  return useMutation(
    ({ petId, file_key, source_file_name }) =>
      postPetPhoto(petId, file_key, source_file_name),
    {
      onSettled: () => queryClient.invalidateQueries([USER_PET_PHOTO_QUERY, petId]),
      onSuccess: async (data, variables) => {
        const { petId, file_key, source_file_name } = variables;
        await queryClient.cancelQueries([USER_PET_PHOTO_QUERY, petId]);

        const photoData = queryClient.getQueryData([USER_PET_PHOTO_QUERY, petId]);
        queryClient.setQueryData([USER_PET_PHOTO_QUERY, petId], {
          ...photoData,

          petId,
          file_key,
          source_file_name,
        });

        return { prevData: photoData };
      },
    }
  );
}

export function useDeletePetPhoto(petId) {
  const queryClient = useQueryClient();

  return useMutation(() => deleteUserPetPhotoById(petId), {
    onSettled: () => {
      queryClient.invalidateQueries([USER_ALL_PET_PHOTOS_QUERY]);
      queryClient.invalidateQueries([USER_PET_PHOTO_QUERY, petId]);
    },

    onMutate: async () => {
      await queryClient.cancelQueries([USER_PET_PHOTO_QUERY, petId]);

      const prevData = queryClient.getQueryData([USER_PET_PHOTO_QUERY, petId]);

      return { prevData };
    },
  });
}

export function useGetSwifterToken(petId) {
  return useMutation(() => getSwifterToken(petId));
}
